import React, { useState } from "react";
import { FrankIcon } from "../../assets/images/FrankIcons";
import { useNavigate } from "react-router-dom";
import {
  Logout,
  PersonAdd,
  PersonOutlined,
  Settings,
  Translate,
} from "@mui/icons-material";
import {
  clearAllDataFromLocalStorage,
  getDataFromLocalStorage,
} from "../../utils/LocalStore.util";
import { Avatar, Divider, ListItemIcon, Menu, MenuItem } from "@mui/material";
import Toast from "../Toast/Toast";

function Header() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let userDetails = getDataFromLocalStorage("user");
  const handleLogout = () => {
    try {
      clearAllDataFromLocalStorage();
      navigate("/");
      Toast.showSuccess("Logout Succesfully");
    } catch (error) {}
  };

  return (
    <>
      <div className="header">
        <div className="frame">
          <div className="left">
            <a href="/" style={{ marginBottom: "-10px" }}>
              <FrankIcon fill={"#ee6931"} height={55} />
            </a>
            <div className="items">
              <div
                className="menu-component menu-container"
                style={{ cursor: "pointer" }}
              >
                <div className="particulier">Particulier </div>

                <div className="dropdown">
                  <div className="dropdown-item" onClick={()=>{navigate("/send-parcel")}}>Envoyer un colis</div>
                  <div className="dropdown-item">Suivre mon colis</div>
                  <div className="dropdown-item">Devenir transporteur</div>
                </div>
              </div>

              <div
                className="menu-component menu-container"
                style={{ cursor: "pointer" }}
              >
                <span className="particulier">Transporteur</span>

                <div className="dropdown">
                  <div className="dropdown-item">Devenir Transporteur</div>
                  <div className="dropdown-item">
                    Voir les annonces pour une livraison
                  </div>
                </div>
              </div>

              <div
                className="menu-component menu-container"
                style={{ cursor: "pointer" }}
              >
                <span className="particulier">Déménagement</span>

                <div className="dropdown">
                  <div className="dropdown-item"> Déménageur particulier</div>
                  <div className="dropdown-item">Déménageur professionnel</div>
                </div>
              </div>

              <div
                className="menu-component menu-container"
                style={{ cursor: "pointer", marginLeft: "20px" }}
              >
                <span className="particulier">Market place</span>
              </div>

              <div
                className="menu-component menu-container"
                style={{ cursor: "pointer" }}
              >
                <span className="particulier">Stockage colis</span>

                <div className="dropdown">
                  <div className="dropdown-item">
                    {" "}
                    Trouver un point relai particulier
                  </div>
                  <div className="dropdown-item">
                    Proposer un espace de point relai
                  </div>
                  <div className="dropdown-item">Consigne bagage</div>
                </div>
              </div>

              <div
                className="menu-component menu-container"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/blogs");
                }}
              >
                <span className="particulier">Blog</span>
              </div>
            </div>
          </div>
          <div className="right">
            <div className="tracker" style={{ cursor: "pointer" }}>
              <div className="line-mail">
                <Translate style={{ fontSize: "25px" }} />
              </div>
            </div>
            <div className="tracker" style={{ cursor: "pointer" }}>
              <div className="line-mail">
                <div className="icon-b"></div>
              </div>
            </div>
            <div className="tracker-c" style={{ cursor: "pointer" }}>
              <div className="search">
                <div className="icon-d"></div>
              </div>
            </div>

            {userDetails ? (
              <div
                className="compte"
                style={{ cursor: "pointer", position: "relative" }}
              >
                <div className="user">
                  <div className="icon-e" onClick={handleClick}></div>
                </div>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      width:'200px',
                      borderRadius:'12px',
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&::before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem  className="dropdown-item" style={{fontFamily:'Raleway-Variable'}} onClick={()=>{
                    navigate("/profile")
                  }}>
                    <Avatar /> Profil
                  </MenuItem>
                 
                  <Divider />
               
                  <MenuItem className="dropdown-item" style={{fontFamily:'Raleway-Variable'}} onClick={handleClose}>
                    <ListItemIcon>
                      <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings
                  </MenuItem>
                  <MenuItem className="dropdown-item" style={{fontFamily:'Raleway-Variable'}} onClick={handleLogout}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            ) : (
              <div
                className="compte"
                onClick={() => {
                  navigate("/login");
                }}
                style={{ cursor: "pointer", position: "relative" }}
              >
                <div className="user">
                  <div className="icon-e"></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
