import { Divider, Grid } from "@mui/material";
import React from "react";
import Header from "../../UiComponent/Header";
import loginImage from "../../assets/images/FrankIcons/login-image.png";
import InputField from "../../UiComponent/TextFeild";
import { LockOpen, MailOutline } from "@mui/icons-material";
import CustomButton from "../../UiComponent/CustomButton";
import { useNavigate } from "react-router-dom";
import Toast from "../../UiComponent/Toast/Toast";
import apiServices from "../../services/RequestHandler";
import { saveDataToLocalStorage } from "../../utils/LocalStore.util";
import { LoginWithAppleIcon, LoginWithFacebookIcon, LoginWithGoogleIcon } from "../../assets/images/FrankIcons";

function LoginPage(props: any) {
  const navigate = useNavigate();
  const { classes } = props;
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      username: null,
      password: null,
    }
  );
  const onInputChange = (e: any) => {
    try {
      const { value, name } = e.target;
      if (value[0] === " ") {
        return;
      }

      setState({
        [name]: value,
      });
    } catch (error) {}
  };

  const handleLogin = async () => {
    try {
      if (!state?.username) {
        Toast.Error("Username cannot be empty");
        return;
      }
      if (!state?.password) {
        Toast.Error("password cannot be empty");
        return;
      }
      let obj = {
        username: state?.username,
        password: state?.password,
      };
      let resp = await apiServices.postFromApi("c2c/login", obj, "");
      if (resp?.status === 201) {
        Toast.showSuccess("Logged in succesfully");
        setState({ step: resp?.step + 1, username: "", password: "" });
        saveDataToLocalStorage("token", resp?.data?.token);
        saveDataToLocalStorage("user", resp?.data);
        navigate("/");
        return;
      } else {
        Toast.Error(
          JSON.stringify(
            resp?.data?.error ??
              resp?.error?.error ??
              resp?.data?.isEmail ??
              resp?.data?.isNotEmpty ??
              "Something went wrong. Please wait a short while and try again. If problem persists then contact to support team."
          )
        );
      }
    } catch (error) {}
  };
  return (
    <div>
      <Grid container>
        <Grid xs={12} item>
          <Header />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        style={{ padding: "50px" }}
        className="display-flex"
      >
        <Grid container spacing={2} style={{ padding: "10px 120px 10px 30px" }}>
          <Grid xs={12} md={6} item>
            <img
              src={loginImage}
              style={{ height: "100%", width: "100%" }}
              alt="login-page"
            />
          </Grid>
          <Grid
            xs={12}
            md={6}
            item
            className="display-flex"
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Grid container spacing={2} style={{ width: "550px" }}>
              <Grid xs={12} item>
                <p className="heading-one">Connexion</p>
                <p className="paragraph-text" style={{ marginTop: "-20px" }}>
                  Insérez votre email ainsi que votre mot de passe
                </p>
              </Grid>
              <Grid xs={12} item>
                <InputField
                  onchange={onInputChange}
                  startIcon={<MailOutline />}
                  input_value={state?.username}
                  input_name={"username"}
                  input_style={{
                    background: "#fff",
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              </Grid>
              <Grid xs={12} item>
                <InputField
                input_type={"password"}
                  startIcon={<LockOpen />}
                  input_value={state?.password}
                  onchange={onInputChange}
                  input_name={"password"}
                  input_style={{
                    background: "#fff",
                    width: "100%",
                    borderRadius: "16px",
                    border: "none",
                  }}
                />
                <p
                  style={{
                    textAlign: "right",
                    fontWeight: "500",
                    marginTop: "-5px",
                    cursor: "pointer",
                  }}
                >
                  J’ai oublié mon mot de passe
                </p>
              </Grid>

              <Grid xs={12} item className="display-flex">
                <CustomButton
                  value={"Me connecter"}
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "#fff",
                    background: "#ee6931",
                    border: "none",
                    borderRadius: "12px",
                  }}
                  onClick={handleLogin}
                />
              </Grid>
              <Grid xs={12} item>
                <p style={{ textAlign: "center" }}>
                  Pas de compte ?{" "}
                  <span
                    style={{ fontWeight: "500", cursor: "pointer" }}
                    onClick={() => {
                      navigate("/sign-up");
                    }}
                  >
                    Créer un compte
                  </span>
                </p>
                <Divider color="grey" style={{ color: "grey" }}>
                  Ou
                </Divider>
              </Grid>
              <Grid xs={12} item>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: "20px",
                  }}
                >
                  <div
                    style={{
                      background: "#fff",
                      padding: "20px 50px 20px 50px",
                      borderRadius: "12px",
                      cursor: "pointer",
                    }}
                  >
                  <LoginWithGoogleIcon />
                  </div>
                  <div
                    style={{
                      background: "#fff",
                      padding: "20px 50px 20px 50px",
                      borderRadius: "12px",
                      cursor: "pointer",
                    }}
                  >
                                    <LoginWithFacebookIcon />

                  </div>
                  <div
                    style={{
                      background: "#fff",
                      padding: "20px 50px 20px 50px",
                      borderRadius: "12px",
                      cursor: "pointer",
                    }}
                  >
                                     <LoginWithAppleIcon />

                  </div>
                </div>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default LoginPage;
