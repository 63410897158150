import React, { useState } from "react";
import "./index.css";
import { FrankIcon } from "../../assets/images/FrankIcons";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import {
  Close,
  ExpandLess,
  ExpandMore,
  PersonOutlineOutlined,
  Search,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import drawerBoxIcon from "../../assets/mobileImages/drawer-box-icon.png";
import { createTheme, styled, ThemeProvider } from "@mui/system";

const CustomListItemText = styled(ListItemText)(({ theme }) => ({
  fontSize: "28px",
  fontWeight: "700",
  color: "#fff",
}));

function MobileView() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [expandedMenu, setExpandedMenu] = useState<string | null>(null); // Track the currently expanded menu

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        (event.type === "keydown" &&
          (event as React.KeyboardEvent).key === "Tab") ||
        (event as React.KeyboardEvent).key === "Shift"
      ) {
        return;
      }
      setDrawerOpen(open);
    };

  const handleMenuClick = (menuId: string) => () => {
    setExpandedMenu(expandedMenu === menuId ? null : menuId);
  };
  return (
    <div className="main-container">
      <div className="header-body-top">
        <div className="header-wrapper">
          <div className="menu-mobile">
            <div className="frank-logo">
              <FrankIcon fill={"#ee6931"} height={50} />
            </div>
            <div className="line-menu-line">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="menu"
                  onClick={toggleDrawer(true)}
                >
                  {!drawerOpen ? (
                    <span style={{ paddingBottom: "20px" }}>
                      <svg
                        width="43px"
                        height="42px"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ marginTop: "-10px" }}
                      >
                        <path
                          d="M4 16H28M4 8H28M4 24H20"
                          stroke="#281208"
                          stroke-width="2.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  ) : (
                    <Close />
                  )}
                </IconButton>

                <Drawer
                  anchor="right"
                  open={drawerOpen}
                  onClose={toggleDrawer(false)}
                  style={{
                    borderRadius: "0px",
                    background: "none",
                    height: "100vh",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1, padding: "20px 0px 0px 20px" }}>
                      <FrankIcon fill={"#ee6931"} height={60} />
                    </div>
                    <div style={{ padding: "20px 20px 0px 0px" }}>
                      <IconButton onClick={toggleDrawer(false)}>
                        <Close style={{ fontSize: "28px", color: "#000" }} />
                      </IconButton>
                    </div>
                  </div>
                  <div role="presentation">
                    <List style={{ paddingTop: "40px", fontSize: "23px" }}>
                      <ListItem onClick={handleMenuClick("particulier")}>
                        <ListItemText primary="Particulier" />
                        {expandedMenu === "particulier" ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItem>
                      <Collapse
                        in={expandedMenu === "particulier"}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List
                          component="div"
                          style={{ paddingLeft: "20px" }}
                          disablePadding
                        >
                          <ListItem button component="a" href="#">
                            <ListItemText primary="Envoyer un colis" />
                          </ListItem>
                          <ListItem button component="a" href="#">
                            <ListItemText primary="Suivre mon colis" />
                          </ListItem>
                          <ListItem button component="a" href="#">
                            <ListItemText primary="Devenir transporteur" />
                          </ListItem>
                        </List>
                      </Collapse>

                      <ListItem
                        button
                        onClick={handleMenuClick("transporteur")}
                      >
                        <ListItemText
                          primary="Transporteur"
                          style={{ fontSize: "43px" }}
                        />
                        {expandedMenu === "transporteur" ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItem>
                      <Collapse
                        in={expandedMenu === "transporteur"}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List
                          component="div"
                          style={{ paddingLeft: "20px" }}
                          disablePadding
                        >
                          <ListItem button component="a" href="#">
                            <ListItemText primary="Devenir Transporteur" />
                          </ListItem>
                          <ListItem button component="a" href="#">
                            <ListItemText primary="Voir les annonces pour une livraison" />
                          </ListItem>
                        </List>
                      </Collapse>

                      <ListItem
                        button
                        onClick={handleMenuClick("demenagement")}
                      >
                        <ListItemText primary="Déménagement" />
                        {expandedMenu === "demenagement" ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItem>
                      <Collapse
                        in={expandedMenu === "demenagement"}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List
                          component="div"
                          style={{ paddingLeft: "20px" }}
                          disablePadding
                        >
                          <ListItem button component="a" href="#">
                            <ListItemText primary="Déménageur particulier" />
                          </ListItem>
                          <ListItem button component="a" href="#">
                            <ListItemText primary="Déménageur professionnel" />
                          </ListItem>
                        </List>
                      </Collapse>

                      <ListItem component="a" href="#" button>
                        <ListItemText primary="Marketplace" />
                      </ListItem>

                      <ListItem button onClick={handleMenuClick("stockage")}>
                        <ListItemText primary="Stockage colis" />
                        {expandedMenu === "stockage" ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItem>
                      <Collapse
                        in={expandedMenu === "stockage"}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List
                          component="div"
                          style={{ paddingLeft: "20px" }}
                          disablePadding
                        >
                          <ListItem button component="a" href="#">
                            <ListItemText primary="Trouver un point relai particulier" />
                          </ListItem>
                          <ListItem button component="a" href="#">
                            <ListItemText primary="Proposer un espace de point relai" />
                          </ListItem>
                          <ListItem button component="a" href="#">
                            <ListItemText primary="Consigne bagage" />
                          </ListItem>
                        </List>
                      </Collapse>

                      <ListItem button component="a" href="#">
                        <ListItemText primary="Blog" />
                      </ListItem>
                    </List>
                  </div>

                  <div style={{ padding: "50px 0px 0px 0px" }}>
                    <List component="div">
                      <ListItem
                        button
                        component="a"
                        href="#"
                        style={{
                          gap: "20px",
                          fontSize: "20px",
                          fontWeight: "700",
                          fontFamily: "Raleway-Variable",
                        }}
                      >
                        <Search /> Tracker mon colis
                      </ListItem>
                      <ListItem
                        button
                        component="a"
                        href="#"
                        style={{
                          gap: "20px",
                          fontSize: "20px",
                          fontWeight: "700",
                          fontFamily: "Raleway-Variable",
                        }}
                      >
                        <PersonOutlineOutlined /> Mon compte
                      </ListItem>
                    </List>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    <img src={drawerBoxIcon} height={"60%"} alt="" />
                  </div>
                </Drawer>
              </div>
            </div>
          </div>
        </div>

        <div className="body">
          <div className="top-section-mob">
            <div className="vector"></div>
            <span className="envoyez-vos-colis-mob">
              Envoyez vos
              <br />
              colis grâce à vos voisins !
            </span>
            <div className="frame_mob">
              <div className="frame-1_mob">
                <div className="frame-2_mob">
                  <span className="envoyer-un-colis_mob">Envoyer un colis</span>
                  <span className="et-nisi-leo_mob">
                    Et nisi leo gravida semper risus accumsan euismod nibh.
                  </span>
                </div>
                <div className="frame-3_mob">
                  <div className="frame-4_mob"></div>
                  <div className="frame-5_mob">
                    <div className="frame-6_mob">
                      <div className="line-arrow-right_mob">
                        <div className="icon-7_mob"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-8_mob">
                <div className="frame-9_mob">
                  <span className="transporter-un-colis_mob">
                    Transporter un colis
                  </span>
                  <span className="et-nisi-leo-a_mob">
                    Et nisi leo gravida semper risus accumsan euismod nibh.
                  </span>
                </div>
                <div className="frame-b_mob">
                  <div className="frame-c_mob"></div>
                  <div className="frame-d_mob">
                    <div className="frame-e_mob">
                      <div className="line-arrow-right-f_mob">
                        <div className="icon-10_mob"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-11_mob">
                <span className="je-demenage_mob">Je déménage</span>
                <div className="cest-par-ici_mob">
                  <span className="cest-par-ici-12_mob">
                    <a href="" style={{ color: "#fff" }}>
                      {" "}
                      C’est par ici !
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="why_mob">
        <div className="menu_mob">
          <span className="achetez-vos-produits_mob">
            Les dernières annonces
            <br />
            mises en ligne
          </span>
          <span className="soyez-livres_mob">
            Transportez les colis de vos voisins !
          </span>
        </div>
        <div className="arguments_mob">
          <div className="frame-13_mob">
            <div className="product_mob">
              <div className="img-container_mob">
                <div className="image_mob"></div>
              </div>
              <div className="frame-14_mob">
                <div className="title_mob">
                  <span className="table-en-bois_mob">Table en bois clair</span>
                  <span className="il-y-a_mob">Il y a 5 minutes</span>
                </div>
                <div className="town-distance_mob">
                  <span className="aix-en-provence_mob">Aix-en-Provence</span>
                  <div className="frame-15_mob">
                    <div className="solid-car_mob">
                      <div className="solid_mob"></div>
                    </div>
                    <span className="km_mob">25 km</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-16_mob">
              <div className="img-container-17_mob"></div>
              <div className="frame-18_mob">
                <div className="title-19_mob">
                  <span className="table-en-bois-1a_mob">
                    Table en bois clair
                  </span>
                  <span className="il-y-a-1b_mob">Il y a 5 minutes</span>
                </div>
                <div className="town-distance-1c_mob">
                  <span className="aix-en-provence-1d_mob">
                    Aix-en-Provence
                  </span>
                  <div className="frame-1e_mob">
                    <div className="solid-car-1f_mob">
                      <div className="solid-20_mob"></div>
                    </div>
                    <span className="km-21_mob">25 km</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="frame-22_mob">
            <div className="product-23_mob">
              <div className="img-container-24_mob"></div>
              <div className="frame-25_mob">
                <div className="title-26_mob">
                  <span className="table-en-bois-27_mob">
                    Table en bois clair
                  </span>
                  <span className="il-y-a-28_mob">Il y a 5 minutes</span>
                </div>
                <div className="town-distance-29_mob">
                  <span className="aix-en-provence-2a_mob">
                    Aix-en-Provence
                  </span>
                  <div className="frame-2b_mob">
                    <div className="solid-car-2c_mob">
                      <div className="solid-2d_mob"></div>
                    </div>
                    <span className="km-2e_mob">25 km</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-2f_mob">
              <div className="img-container-30_mob">
                <div className="image-31_mob"></div>
              </div>
              <div className="frame-32_mob">
                <div className="title-33_mob">
                  <span className="table-en-bois-clair_mob">
                    Table en bois clair
                  </span>
                  <span className="il-y-a-minutes_mob">Il y a 5 minutes</span>
                </div>
                <div className="town-distance-34_mob">
                  <span className="aix-en-provence-35_mob">
                    Aix-en-Provence
                  </span>
                  <div className="frame-36_mob">
                    <div className="solid-car-37_mob">
                      <div className="solid-38_mob"></div>
                    </div>
                    <span className="km-39_mob">25 km</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="button_mob">
          <span className="voir-tous-les-produits_mob">
            Voir tous les produits
          </span>
        </div>
      </div>
      <div className="products-wrapper_mob">
        <div className="vector-3a_mob"></div>
        <div className="vector-3b_mob"></div>
        <div className="menu-3c_mob">
          <span className="achetez-vos-produits-3d_mob">
            Comment ça
            <br />
            marche ?
          </span>
        </div>
        <div className="story_mob">
          <div className="img-wrapper_mob">
            <div className="device_mob"></div>
            <div className="frame-3e_mob">
              <div className="rectangle_mob"></div>
            </div>
            <div className="top-speaker_mob"></div>
            <div className="frame-3f_mob"></div>
          </div>
          <div className="inscription-et-creation-de-profil_mob">
            <span className="inscription-et-creation-de-profil-40_mob">
              Inscription et
              <br />
              Création de Profil
            </span>
            <span className="creez-un-compte_mob">
              Créez un compte en fournissant vos informations de base et
              vérifiez votre identité pour assurer la sécurité de la communauté.
            </span>
          </div>
          <div className="voir-tous-les-produits-41_mob">
            <span className="story-42_mob">En savoir plus</span>
          </div>
        </div>
        <div className="img-wrapper-43_mob">
          <div className="device-44_mob">
            <div className="frame-45_mob"></div>
            <div className="rectangle-46_mob">
              <div className="top-speaker-47_mob"></div>
            </div>
            <div className="frame-48_mob"></div>
            <div className="publication-ou-selection-dune-livraison_mob"></div>
          </div>
          <div className="publication-ou-selection-dune-livraison-49_mob">
            <span className="publiez-une-demande_mob">
              Publication ou Sélection d'une Livraison
            </span>
            <span className="voir-tous-les-produits-4a_mob">
              Publiez une demande de livraison en indiquant les détails du colis
              et la destination, ou parcourez les offres de livraison
              disponibles et choisissez celle qui vous convient.
            </span>
          </div>
          <div className="story-4b_mob">
            <span className="img-wrapper-4c_mob">En savoir plus</span>
          </div>
        </div>
        <div className="device-4d_mob">
          <div className="frame-4e_mob">
            <div className="rectangle-4f_mob"></div>
            <div className="top-speaker-50_mob">
              <div className="frame-51_mob"></div>
            </div>
            <div className="suivi-et-reception-du-colis_mob"></div>
            <div className="suivi-et-reception-du-colis-52_mob"></div>
          </div>
          <div className="suivez-votre-colis_mob">
            <span className="voir-tous-les-produits-53_mob">
              Suivi et Réception du Colis
            </span>
            <span className="why-54_mob">
              Suivez votre colis en temps réel grâce à notre traçage GPS et
              recevez des notifications à chaque étape jusqu'à la livraison
              sécurisée à l'adresse indiquée.
            </span>
          </div>
          <div className="achetez-vos-produits-55_mob">
            <span className="frame-56_mob">En savoir plus</span>
          </div>
        </div>
      </div>
      <div className="item_mob">
        <span className="img-container-57_mob">
          Achetez vos produits
          <br />
          sur notre
          <br />
          market place
        </span>
        <div className="table_mob">
          <div className="right_mob">
            <div className="title-58_mob">
              <div className="table-en-bois-clair-59_mob"></div>
            </div>
            <div className="mise-en-ligne-il-y-a_mob">
              <div className="euros_mob">
                <span className="item-5a_mob">Table en bois clair</span>
                <span className="img-container-5b_mob">
                  Mise en ligne il y a - 5 minutes
                </span>
              </div>
              <span className="right-5c_mob">58 €</span>
            </div>
          </div>
          <div className="title-5d_mob">
            <div className="table-en-bois-clair-5e_mob"></div>
            <div className="mise-en-ligne-il-y-a-5f_mob">
              <div className="euros-60_mob">
                <span className="item-61_mob">Playstation 5</span>
                <span className="img-container-62_mob">
                  Mise en ligne il y a - 5 minutes
                </span>
              </div>
              <span className="right-63_mob">100 €</span>
            </div>
          </div>
          <div className="title-64_mob">
            <div className="table-en-bois-clair-65_mob"></div>
            <div className="mise-en-ligne-il-y-a-66_mob">
              <div className="group-13_mob">
                <span className="text-2c_mob">Casque Apple</span>
                <span className="text-2d_mob">
                  Mise en ligne il y a - 5 minutes
                </span>
              </div>
              <span className="euro_mob">200 €</span>
            </div>
          </div>
          <div className="column_mob" style={{ width: "100%" }}>
            <div className="vector-67_mob"></div>
            <div className="vector-68_mob"></div>
            <div className="frame-69_mob">
              <span className="market-place_mob">Par ici la market place</span>
              <div className="frame-6a_mob">
                <div className="line-arrow-right-6b_mob">
                  <div className="icon-6c_mob"></div>
                </div>
              </div>
            </div>
            <div className="mobile-white_mob">
              <div className="flex-row-be_mob">
                <div className="top-speaker-6d_mob"></div>
                <div className="status-bar_mob">
                  <div className="bgr_mob"></div>
                  <div className="time_mob">
                    <span className="time-6e_mob">8:15</span>
                  </div>
                  <div className="connections_mob">
                    <div className="wifi_mob">
                      <div className="wifi-6f_mob"></div>
                    </div>
                    <div className="battery_mob"></div>
                    <div className="cellular-connection_mob">
                      <div className="cellular-connection-70_mob"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-row-ed_mob">
                <div className="insert-your-screen-here_mob"></div>
                <div className="device-71_mob"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="why-72_mob">
        <span className="choose-frank_mob">
          Pourquoi choisir
          <br />
          Frank ?
        </span>
        <div className="arguments-73_mob">
          <div className="arguments-74_mob">
            <div className="frame-75_mob">
              <div className="frame-76_mob"></div>
            </div>
            <div className="frame-77_mob">
              <span
                className="reliability-security_mob"
                style={{ textAlign: "center" }}
              >
                Fiabilité et sécurité
              </span>
              <span
                className="delivery-insurance_mob"
                style={{ textAlign: "center" }}
              >
                Livraisons ponctuelles et colis en bon état avec assurances.
              </span>
            </div>
          </div>
          <div className="arguments-78_mob">
            <div className="frame-79_mob">
              <div className="frame-7a_mob"></div>
            </div>
            <div className="frame-7b_mob">
              <span
                className="rates-flexibility_mob"
                style={{ textAlign: "center" }}
              >
                Tarifs et flexibilité
              </span>
              <span
                className="competitive-pricing_mob"
                style={{ textAlign: "center" }}
              >
                Prix compétitifs, options variées (express, standard), choix des
                créneaux et points de dépôt/retrait.
              </span>
            </div>
          </div>
          <div className="arguments-7c_mob">
            <div className="frame-7d_mob">
              <div className="frame-7e_mob"></div>
            </div>
            <div className="frame-7f_mob">
              <span
                className="tracking-customer-service_mob"
                style={{ textAlign: "center" }}
              >
                Suivi et service client
              </span>
              <span
                className="real-time-support_mob"
                style={{ textAlign: "center" }}
              >
                Suivi en temps réel et support client réactif.
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="why-a3_mob">
        <div className="menu-a4_mob">
          <span
            className="achetez-vos-produits-a5_mob"
            style={{ textAlign: "center" }}
          >
            Nos actus
          </span>
          <span className="soyez-livres-a6_mob" style={{ textAlign: "center" }}>
            Soyez livrés par nos Franks ou nos partenaires
          </span>
        </div>
        <div className="frame-a7_mob">
          <div className="actu_mob">
            <div className="img-wrapper-blue_mob">
              <div className="text-a8_mob"></div>
            </div>
            <div className="title-a9_mob">
              <div className="du-nouveau-chez-frank_mob">
                <span className="il-y-a-minutes-aa_mob">
                  Du nouveau chez Frank
                </span>
                <span className="lorem-ipsum-dolor_mob">Il y a 5 minutes</span>
              </div>
              <span className="voir-larticle_mob">
                Lorem ipsum dolor sit amet consectetur. Sapien pharetra
                adipiscing odio et bibendum duis. Auctor scelerisque tellus
                tortor euismod...
              </span>
              <span className="actu-ab_mob">Voir l’artcile</span>
            </div>
          </div>
          <div className="img-wrapper-orange_mob">
            <div className="text-ac_mob">
              <div className="title-ad_mob"></div>
            </div>
            <div className="du-nouveau-chez-frank-ae_mob">
              <div className="il-y-a-minutes-af_mob">
                <span className="lorem-ipsum-dolor-b0_mob">
                  Du nouveau chez Frank
                </span>
                <span className="voir-larticle-b1_mob">Il y a 5 minutes</span>
              </div>
              <span className="actu-b2_mob">
                Lorem ipsum dolor sit amet consectetur. Sapien pharetra
                adipiscing odio et bibendum duis. Auctor scelerisque tellus
                tortor euismod...
              </span>
              <span className="img-wrapper-blue-b3_mob">Voir l’artcile</span>
            </div>
          </div>
          <div className="text-b4_mob">
            <div className="title-b5_mob">
              <div className="du-nouveau-chez-frank-b6_mob"></div>
            </div>
            <div className="il-y-a-minutes-b7_mob">
              <div className="lorem-ipsum-dolor-b8_mob">
                <span className="voir-larticle-b9_mob">
                  Du nouveau chez Frank
                </span>
                <span className="button-ba_mob">Il y a 5 minutes</span>
              </div>
              <span className="voir-tous-les-produits-bb_mob">
                Lorem ipsum dolor sit amet consectetur. Sapien pharetra
                adipiscing odio et bibendum duis. Auctor scelerisque tellus
                tortor euismod...
              </span>
              <span className="bottom_mob">Voir l’artcile</span>
            </div>
          </div>
        </div>
        <div className="faq_mob">
          <span className="des-questions_mob">Voir toutes les actus</span>
        </div>
      </div>
      <div className="frame-bc_mob">
        <div className="frame-bd_mob">
          <span className="line_mob">Des questions ?</span>
          <div className="faq-be_mob">
            <div className="quest-ce-que-frank_mob">
         

              <div className="line-chevron-down_mob"></div>
              <div style={{ width: "100%" }}>
                <Accordion
                  elevation={0}
                  style={{
                    border: "0px",
                    background: "#fce6dd",
                    width: "100%",
                  }}
                >
                  <AccordionSummary
                    style={{
                      border: "none",
                      fontFamily: "Raleway-Variable",
                      fontWeight: "500",
                    }}
                    expandIcon={
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                          textAlign: "justify",
                        }}
                      >
                        {" "}
                        <ExpandMore style={{ textAlign: "right" }} />{" "}
                      </div>
                    }
                  >
                    <span style={{ width: "100%" }}>
                      {" "}
                      Qu’est-ce que Frank ?
                    </span>
                  </AccordionSummary>
                  <AccordionDetails style={{ width: "100%" }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="comment-fonctionne-la-livraison_mob"></div>
              <div style={{ width: "100%" }}>
                <Accordion
                  elevation={0}
                  style={{
                    border: "0px",
                    background: "#fce6dd",
                    width: "100%",
                  }}
                >
                  <AccordionSummary
                    style={{
                      border: "none",
                      width: "100%",
                      fontFamily: "Raleway-Variable",
                      fontWeight: "500",
                    }}
                    expandIcon={
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        {" "}
                        <ExpandMore style={{ textAlign: "right" }} />{" "}
                      </div>
                    }
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="span-what-is-frank_mob"
                  >
                    Comment fonctionne la livraison ?
                  </AccordionSummary>
                  <AccordionDetails style={{ width: "100%" }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="quest-ce-que-frank-c7_mob"></div>
              <div style={{ width: "100%" }}>
                <Accordion
                  elevation={0}
                  style={{
                    border: "0px",
                    background: "#fce6dd",
                    width: "100%",
                  }}
                >
                  <AccordionSummary
                    style={{
                      border: "none",
                      width: "100%",
                      fontFamily: "Raleway-Variable",
                      fontWeight: "500",
                    }}
                    expandIcon={
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        {" "}
                        <ExpandMore style={{ textAlign: "right" }} />{" "}
                      </div>
                    }
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="span-what-is-frank_mob"
                  >
                    Que se passe-t-il si mon colis est perdu / volé ?
                  </AccordionSummary>
                  <AccordionDetails style={{ width: "100%" }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </AccordionDetails>
                </Accordion>
                {/* <span className="span-what-is-frank">Qu’est-ce que Frank ?</span>
              <div className="div-line-chevron-down">
                <div className="div-icon"></div>
              </div> */}
              </div>
              <div className="faq-cb_mob"></div>
              <div style={{ width: "100%" }}>
                <Accordion
                  elevation={0}
                  style={{
                    border: "0px",
                    background: "#fce6dd",
                    width: "100%",
                  }}
                >
                  <AccordionSummary
                    style={{
                      border: "none",
                      width: "100%",
                      fontFamily: "Raleway-Variable",
                      fontWeight: "500",
                    }}
                    expandIcon={
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        {" "}
                        <ExpandMore style={{ textAlign: "right" }} />{" "}
                      </div>
                    }
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="span-what-is-frank_mob"
                  >
                    Y a-t-il une limite de poids ?
                  </AccordionSummary>
                  <AccordionDetails style={{ width: "100%" }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </AccordionDetails>
                </Accordion>
                {/* <span className="span-what-is-frank">Qu’est-ce que Frank ?</span>
              <div className="div-line-chevron-down">
                <div className="div-icon"></div>
              </div> */}
              </div>
              <div className="line-cf_mob"></div>
            </div>
          </div>
        </div>
        <div className="seo_mob">
          <div className="background_mob">
            <div className="vector-d0_mob"></div>
            <div className="menu-d1_mob">
              <span className="achetez-vos-produits-d2_mob">
                Tous savoir
                <br />
                sur Frank
              </span>
            </div>
            <div className="tout-savoir_mob">
              <div className="solid-heart-circle_mob">
                <div className="solid-d3_mob">
                  <div className="redirection_mob"></div>
                </div>
                <span className="text-53_mob">Redirection 1</span>
              </div>
              <div className="frame-d4_mob">
                <div className="solid-heart-circle-d5_mob">
                  <div className="solid-d6_mob"></div>
                </div>
                <span className="redirection-d7_mob">Redirection 1</span>
              </div>
              <div className="frame-d8_mob">
                <div className="solid-heart-circle-d9_mob">
                  <div className="solid-da_mob"></div>
                </div>
                <span className="redirection-db_mob">Redirection 1</span>
              </div>
              <div className="frame-dc_mob">
                <div className="solid-heart-circle-dd_mob">
                  <div className="solid-de_mob"></div>
                </div>
                <span className="redirection-df_mob">Redirection 1</span>
              </div>
              <div className="frame-e0_mob">
                <div className="solid-heart-circle-e1_mob">
                  <div className="solid-e2_mob"></div>
                </div>
                <span className="redirection-e3_mob">Redirection 1</span>
              </div>
              <div className="frame-e4_mob">
                <div className="solid-heart-circle-e5_mob">
                  <div className="solid-e6_mob"></div>
                </div>
                <span className="redirection-e7_mob">Redirection 1</span>
              </div>
              <div className="frame-e8_mob">
                <div className="solid-heart-circle-e9_mob">
                  <div className="solid-ea_mob"></div>
                </div>
                <span className="redirection-eb_mob">Redirection 1</span>
              </div>
              <div className="frame-ec_mob">
                <div className="solid-heart-circle-ed_mob">
                  <div className="solid-ee_mob"></div>
                </div>
                <span className="redirection-ef_mob">Redirection 1</span>
              </div>
            </div>
            <div className="frame-f0_mob">
              <span className="voir-toutes-les-categories_mob">
                Voir toutes les catégories
              </span>
              <div className="line-chevron-down-f1_mob">
                <div className="icon-f2_mob"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="get-the-app-wrapper_mob">
          <div className="background-f3_mob">
            <div className="vector-f4_mob"></div>
            <div className="container_mob">
              <div className="frame-f5_mob">
                <span className="download-app_mob">DOWNLOAD APP</span>
                <span className="get-frank-mobile-application_mob">
                  Get Frank Mobile
                  <br />
                  Application
                </span>
              </div>
              <div className="frame-f6_mob">
                <div className="line-arrow-right-f7_mob">
                  <div className="icon-f8_mob"></div>
                </div>
              </div>
              <span className="download-customer-app-and-transporter-app_mob">
                Download Customer App and Transporter App for free.
              </span>
              <div className="frame-f9_mob">
                <div className="link_mob">
                  <div className="google-play-store-badge-svg_mob">
                    <div className="google-play-store-badge-svg-fill_mob">
                      <div className="google-play-store-badge-svg-fa_mob"></div>
                    </div>
                  </div>
                </div>
                <div className="link-fb_mob">
                  <div className="app-store-badge-svg_mob">
                    <div className="app-store-badge-svg-fill_mob">
                      <div className="app-store-badge-svg-fc_mob">
                        <div className="group_mob">
                          <div className="vector-fd_mob"></div>
                          <div className="vector-fe_mob"></div>
                          <div className="group-ff_mob">
                            <div className="group-100_mob">
                              <div className="vector-101_mob"></div>
                              <div className="vector-102_mob"></div>
                            </div>
                          </div>
                          <div className="group-103_mob">
                            <div className="group-104_mob">
                              <div className="vector-105_mob"></div>
                              <div className="flex-row_mob">
                                <div className="vector-106_mob"></div>
                                <div className="regroup_mob">
                                  <div className="vector-107_mob"></div>
                                  <div className="vector-108_mob"></div>
                                </div>
                              </div>
                              <div className="flex-row-dea_mob">
                                <div className="vector-109_mob"></div>
                                <div className="regroup-10a_mob">
                                  <div className="vector-10b_mob"></div>
                                  <div className="vector-10c_mob"></div>
                                </div>
                                <div className="vector-10d_mob"></div>
                                <div className="vector-10e_mob"></div>
                                <div className="vector-10f_mob"></div>
                                <div className="vector-110_mob"></div>
                                <div className="vector-111_mob"></div>
                                <div className="vector-112_mob"></div>
                              </div>
                            </div>
                          </div>
                          <div className="group-113_mob">
                            <div className="flex-row-a_mob">
                              <div className="vector-114_mob"></div>
                              <div className="regroup-115_mob">
                                <div className="vector-116_mob"></div>
                                <div className="vector-117_mob"></div>
                              </div>
                              <div className="vector-118_mob"></div>
                              <div className="vector-119_mob"></div>
                            </div>
                            <div className="flex-row-d_mob">
                              <div className="vector-11a_mob"></div>
                              <div className="regroup-11b_mob">
                                <div className="vector-11c_mob"></div>
                                <div className="vector-11d_mob"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="link-11e_mob">
                  <div className="huawei-appgallery-badge_mob">
                    <div className="huawei-appgallery-badge-fill_mob">
                      <div className="huawei-appgallery-badge-11f_mob">
                        <div className="group-120_mob"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-121_mob">
              <div className="screen-png_mob"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="frame-122_mob">
        <div className="frame-123_mob">
          <div className="tracking_mob">
            <div className="icon-svg_mob">
              <div className="icon-svg-fill_mob">
                <div className="icon-svg-124_mob">
                  <div className="mask-group_mob">
                    <div className="group-125_mob">
                      <div className="vector-126_mob"></div>
                    </div>
                  </div>
                  <div className="vector-127_mob"></div>
                  <div className="vector-128_mob"></div>
                </div>
              </div>
            </div>
            <div className="frame-129_mob">
              <span className="heading-tracking_mob">Tracking</span>
              <span className="track-order_mob">
                Click here to track the status of your order.
              </span>
            </div>
          </div>
          <div className="tracking-12a_mob">
            <div className="icon-svg-12b_mob">
              <div className="icon-svg-fill-12c_mob">
                <div className="icon-svg-12d_mob"></div>
              </div>
            </div>
            <div className="frame-12e_mob">
              <span className="heading-support_mob">Support</span>
              <span className="question-inquiry_mob">
                For any question or inquiry, call at
              </span>
              <span className="phone-number_mob">
                <a
                  href="tel:+33 1 42 764040"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  {" "}
                  +33 1 42 764040{" "}
                </a>
              </span>
            </div>
          </div>
        </div>
        <div className="footer_mob">
          <div className="shape-png_mob"></div>
          <div className="shape-png-12f_mob"></div>
          <div className="logo_mob">
            <div className="link-130_mob">
              <div className="white-logo_mob">
                <div className="white-logo-fill_mob">
                  <div className="white-logo-131_mob">
                    <div className="group-132_mob">
                      <div className="layer_mob"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="list_mob">
              <div className="item-link_mob">
                <div className="symbol_mob"></div>
              </div>
              <div className="item-link-133_mob">
                <div className="symbol-134_mob"></div>
              </div>
              <div className="item-link-135_mob">
                <div className="symbol-136_mob"></div>
              </div>
            </div>
          </div>
          <div className="list-137_mob">
            <span className="customer-help_mob">Customer Help</span>
            <span className="support-center_mob">Support Center</span>
            <span className="my-order-status_mob">My Order Status</span>
            <span className="home_mob">Home</span>
          </div>
          <div className="list-138_mob">
            <span className="rider_mob">Rider</span>
            <span className="air-rider_mob">Air Rider</span>
            <span className="fleet-manager_mob">Fleet Manager</span>
            <span className="sub-rider_mob">Sub-Rider</span>
          </div>
          <div className="list-139_mob">
            <span className="e-merchant-store_mob">E-merchant Store</span>
            <span className="space-store_mob">Space Store</span>
            <span className="bc-store_mob">B2C Store</span>
          </div>
          <span className="frank_mob">Frank © 2023</span>
        </div>
      </div>
    </div>
  );
}

export default MobileView;
