import "./index.css";
import { AppStoreFR, FrankIcon } from "../../assets/images/FrankIcons";
import googlePlayBtn from "../../assets/mobileImages/french-google-play.png";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import {
  ArrowDownward,
  ExpandMore,
  KeyboardArrowDown,
  Translate,
} from "@mui/icons-material";
import MobileView from "../MobileView";
import homeSlider from "../../assets/images/FrankIcons/homeSlider.png";
import { useNavigate } from "react-router-dom";
import Header from "../../UiComponent/Header";

function LandingPage() {
  const navigate = useNavigate();

  return (
    <>
      <div className="web-screen">
        <div className="main-container">
          <Header />

          <div className="content">
            <div className="top-section">
              <Grid container spacing={2}>
                <Grid xs={12} md={6} item>
                  <div className="frame-f">
                    <div className="vector"></div>
                    <span className="envoyez-vos-colis">
                      Envoyez vos
                      <br />
                      colis grâce à vos voisins !
                    </span>
                    <div className="frame-10">
                      <div className="frame-11">
                        <div className="frame-12" style={{ cursor: "pointer" }}>
                          <div
                            className="frame-13"
                            style={{ cursor: "pointer" }}
                          >
                            <div className="line-arrow-right">
                              <div className="icon-14"></div>
                            </div>
                          </div>
                          <div
                            className="frame-15"
                            style={{ cursor: "pointer" }}
                          >
                            <span className="envoyer-un-colis">
                              Envoyer
                              <br />
                              un colis
                            </span>
                            <span className="et-nisi-leo">
                              Et nisi leo gravida semper risus accumsan euismod
                              nibh.
                            </span>
                          </div>
                        </div>
                        <div className="frame-16" style={{ cursor: "pointer" }}>
                          <div
                            className="frame-17"
                            style={{ cursor: "pointer" }}
                          >
                            <div className="line-arrow-right-18">
                              <div className="icon-19"> </div>
                            </div>
                          </div>
                          <div className="frame-1a">
                            <span className="transporter-un-colis">
                              Transporter
                              <br />
                              un colis
                            </span>
                            <span className="et-nisi-leo-1b">
                              Et nisi leo gravida semper risus accumsan euismod
                              nibh.
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="frame-1c">
                        <span className="je-demenage">Je déménage</span>
                        <div className="cest-par-ici">
                          <span
                            className="cest-par-ici-1d"
                            style={{ cursor: "pointer" }}
                          >
                            C’est par ici !
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  xs={12}
                  md={6}
                  item
                  style={{ borderRadius: "48px", cursor: "pointer" }}
                >
                  {/* <div className="frame-1e"></div> */}
                  <section className="slider_container ">
                    <section className="slider">
                      <div className="slide one">
                        <img src={homeSlider} alt="" />

                        {/* <span className="caption">slide one</span> */}
                      </div>
                      <div className="slide two">
                        <img src={homeSlider} alt="" />
                        {/* <span className="caption">slide two</span> */}
                      </div>
                      <div className="slide three">
                        <img src={homeSlider} alt="" />
                        {/* <span className="caption">slide three</span> */}
                      </div>
                      <div className="slide four">
                        <img src={homeSlider} alt="" />
                        {/* <span className="caption">slide four</span> */}
                      </div>
                      <div className="slide four">
                        <img src={homeSlider} alt="" />
                        {/* <span className="caption">slide five</span> */}
                      </div>
                    </section>
                  </section>
                </Grid>
              </Grid>
            </div>
            <div className="body">
              <div className="frame-1f">
                <span
                  className="pourquoi-choisir-frank"
                  style={{ textAlign: "center" }}
                >
                  Pourquoi choisir Frank ?
                </span>
              </div>
              <div className="arguments">
                <Grid container spacing={2}>
                  <Grid xs={12} md={4} item>
                    <div className="arguments-20">
                      <div
                        className="frame-21"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="frame-22"></div>
                      </div>
                      <div
                        className="frame-23"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span
                          className="fiabilite-et-securite"
                          style={{ textAlign: "center", marginTop: "10px" }}
                        >
                          Fiabilité et sécurité
                        </span>
                        <span
                          className="livraisons-ponctuelles"
                          style={{ textAlign: "center" }}
                        >
                          Livraisons ponctuelles et colis en bon état avec
                          assurances.
                        </span>
                      </div>
                    </div>
                  </Grid>
                  <Grid xs={12} md={4} item>
                    <div className="arguments-24">
                      <div
                        className="frame-25"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="frame-26"></div>
                      </div>
                      <div
                        className="frame-27"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span
                          className="fiabilite-et-securite-28"
                          style={{ textAlign: "center" }}
                        >
                          Tarifs et flexibilité
                        </span>
                        <span
                          className="livraisons-ponctuelles-29"
                          style={{ textAlign: "center" }}
                        >
                          Prix compétitifs, options variées (express, standard),
                          choix des créneaux et points de dépôt/retrait.
                        </span>
                      </div>
                    </div>
                  </Grid>
                  <Grid xs={12} md={4} item>
                    <div className="arguments-2a">
                      <div className="frame-2b">
                        <div className="img-7"></div>
                      </div>
                      <div className="frame-2c">
                        <span
                          className="reliability-security"
                          style={{ textAlign: "center" }}
                        >
                          Suivi et service client
                        </span>
                        <span
                          className="delivery-service"
                          style={{ textAlign: "center" }}
                        >
                          Suivi en temps réel et support client réactif.
                        </span>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="vector-90">
              <div className="menu-91">
                <div className="achetez-vos-produits-92"></div>
                <div className="market-place-container"></div>
                <div className="frame-93">
                  <span className="item">
                    Achetez vos produits
                    <br />
                    sur notre market place
                  </span>
                </div>
                <div className="img-container">
                  <Grid container spacing={2}>
                    <Grid xs={12} md={6} item>
                      <div className="table">
                        <div className="right-94">
                          <div className="title-95">
                            <div
                              className="table-en-bois-clair"
                              style={{ width: "100%" }}
                            ></div>
                          </div>
                          <div className="mise-en-ligne">
                            <div className="euro">
                              <span
                                className="item-96"
                                style={{ cursor: "pointer" }}
                              >
                                Table en bois clair
                              </span>
                              <span className="img-container-97">
                                Mise en ligne il y a - 5 minutes
                              </span>
                            </div>
                            <span className="right-98">58 €</span>
                          </div>
                        </div>
                        <div className="title-99">
                          <div className="playstation-5"></div>
                          <div className="mise-en-ligne-9a">
                            <div className="euro-9b">
                              <span
                                className="item-9c"
                                style={{ cursor: "pointer" }}
                              >
                                Playstation 5
                              </span>
                              <span className="img-container-9d">
                                Mise en ligne il y a - 5 minutes
                              </span>
                            </div>
                            <span className="right-9e">100 €</span>
                          </div>
                        </div>
                        <div className="title-9f">
                          <div className="pic-13"></div>
                          <div className="box-24">
                            <div className="section-20">
                              <span
                                className="table-en-bois-clair-a0"
                                style={{ cursor: "pointer" }}
                              >
                                Casque Apple
                              </span>
                              <span className="mise-en-ligne-a1">
                                Mise en ligne il y a - 5 minutes
                              </span>
                            </div>
                            <span className="euro-a2">200 €</span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid xs={12} md={6} item>
                      <div className="column">
                        <div className="vector-a3"></div>
                        <div className="vector-a4"></div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            padding: "20px",
                          }}
                        >
                          <div
                            className="frame-ae"
                            style={{ cursor: "pointer" }}
                          >
                            <div className="arrow-right">
                              <div className="icon-af"></div>
                            </div>
                          </div>
                        </div>

                        <div className="frame-a5" style={{ display: "flex" }}>
                          <div style={{ flex: 1, width: "100%" }}>
                            <span className="market-place-a6">
                              Par ici la <br />
                              market place
                            </span>
                          </div>
                        </div>
                        <div className="desktop-wires">
                          <div className="screen-stroke"></div>
                          <div className="desktop-gray">
                            <div className="top-speaker-cam">
                              <div className="rectangle-a7"></div>
                              <div className="ellipse"></div>
                              <div className="ellipse-a8"></div>
                            </div>
                            <div className="mask-group">
                              <div className="device-screen">
                                <div className="browser-bar">
                                  <div className="rectangle-a9"></div>
                                  <div className="middle-search">
                                    <div className="rectangle-aa"></div>

                                    <div className="search-px"></div>
                                  </div>
                                  <div className="buttons">
                                    <div className="rectangle-ab"></div>
                                    <div className="ellipse-ac"></div>
                                    <div className="polygon"></div>
                                  </div>
                                </div>
                                <div className="insert-screen"></div>
                              </div>
                            </div>
                            <div className="device-ad"></div>
                          </div>
                        </div>
                        {/* <div className="frame-ae" style={{ cursor: "pointer" }}> */}
                        {/* <div className="arrow-right"> */}
                        {/* <div className="icon-af"></div> */}
                        {/* </div> */}
                        {/* </div> */}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>

            <div className="story">
              <div className="vector-70"></div>
              <div className="vector-71"></div>
              <div className="vector-72"></div>
              <div className="menu-73">
                <span className="achetez-vos-produits">
                  Comment ça marche ?
                </span>
              </div>
              <div className="arguments-74">
                <div className="story-75">
                  <div className="frame-76">
                    <span className="inscription-et-creation-de-profil">
                      Inscription et Création de Profil
                    </span>
                    <span className="creer-un-compte">
                      Créez un compte en fournissant vos informations de base et
                      vérifiez votre identité pour assurer la sécurité de la
                      communauté.
                    </span>

                    <div className="frame-88" style={{ cursor: "pointer" }}>
                      <span className="img-wrapper-89">En savoir plus</span>
                    </div>
                  </div>
                  <div className="frame-78">
                    <div className="img-wrapper">
                      <div className="device"></div>
                      <div className="frame-79">
                        <div className="rectangle"></div>
                      </div>
                      <div className="top-speaker"></div>
                      <div className="story-7a"></div>
                    </div>
                  </div>
                </div>
                <div className="frame-7b">
                  <div className="img-wrapper-7c">
                    <div className="device-7d">
                      <div className="frame-7e"></div>
                      <div className="rectangle-7f">
                        <div className="top-speaker-80"></div>
                      </div>
                      <div className="frame-81"></div>
                      <div className="publication-ou-selection-dune-livraison"></div>
                    </div>
                  </div>
                  <div className="publiez-une-demande">
                    <span className="button-82">
                      Publication ou Sélection d'une Livraison
                    </span>
                    <span className="en-savoir-plus-83">
                      Publiez une demande de livraison en indiquant les détails
                      du colis et la destination, ou parcourez les offres de
                      livraison disponibles et choisissez celle qui vous
                      convient.
                    </span>
                    <div className="story-84" style={{ cursor: "pointer" }}>
                      <span className="frame-85">En savoir plus</span>
                    </div>
                  </div>
                </div>
                <div className="suivi-et-reception-du-colis">
                  <div className="suivez-votre-colis">
                    <span className="button-86">
                      Suivi et Réception du Colis
                    </span>
                    <span className="en-savoir-plus-87">
                      Suivez votre colis en temps réel grâce à notre traçage GPS
                      et recevez des notifications à chaque étape jusqu'à la
                      livraison sécurisée à l'adresse indiquée.
                    </span>
                    <div className="frame-88" style={{ cursor: "pointer" }}>
                      <span className="img-wrapper-89">En savoir plus</span>
                    </div>
                  </div>
                  <div className="device-8a">
                    <div className="frame-8b">
                      <div className="rectangle-8c"></div>
                      <div className="top-speaker-8d">
                        <div className="last"></div>
                      </div>
                      <div className="frame-8e"></div>
                      <div className="vector-8f"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="market-place-2d">
              <div className="menu">
                <span className="buy-products">
                  Les dernières annonces
                  <br />
                  mises en ligne
                </span>
                <span className="delivery-partners">
                  Transportez les colis de vos voisins !
                </span>
              </div>
              <div className="arguments-2e">
                <div className="frame-2f">
                  <div className="product">
                    <div className="frame-30">
                      <div className="image"></div>
                    </div>
                    <div className="frame-31">
                      <div className="title">
                        <span className="table-wood">Table en bois clair</span>
                        <span className="time">Il y a 5 minutes</span>
                      </div>
                      <div className="town-distance">
                        <span className="aix-provence">Aix-en-Provence</span>
                        <div className="frame-32">
                          <div className="solid-car">
                            <div className="solid"></div>
                          </div>
                          <span className="distance">25 km</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-33">
                    <div className="frame-34"></div>
                    <div className="frame-35">
                      <div className="title-36">
                        <span className="table-wood-37">
                          Table en bois clair
                        </span>
                        <span className="time-38">Il y a 5 minutes</span>
                      </div>
                      <div className="town-distance-39">
                        <span className="aix-provence-3a">Aix-en-Provence</span>
                        <div className="frame-3b">
                          <div className="solid-car-3c">
                            <div className="solid-3d"></div>
                          </div>
                          <span className="distance-3e">25 km</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-3f">
                  <div className="product-40">
                    <div className="frame-41"></div>
                    <div className="frame-42">
                      <div className="title-43">
                        <span className="table-wood-44">
                          Table en bois clair
                        </span>
                        <span className="time-45">Il y a 5 minutes</span>
                      </div>
                      <div className="town-distance-46">
                        <span className="aix-provence-47">Aix-en-Provence</span>
                        <div className="frame-48">
                          <div className="solid-car-49">
                            <div className="solid-4a"></div>
                          </div>
                          <span className="distance-4b">25 km</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-4c">
                    <div className="frame-4d">
                      <div className="image-4e"></div>
                    </div>
                    <div className="frame-4f">
                      <div className="title-50">
                        <span className="table-wood-51">
                          Table en bois clair
                        </span>
                        <span className="time-52">Il y a 5 minutes</span>
                      </div>
                      <div className="town-distance-53">
                        <span className="aix-provence-54">Aix-en-Provence</span>
                        <div className="frame-55">
                          <div className="solid-car-56">
                            <div className="solid-57"></div>
                          </div>
                          <span className="distance-58">25 km</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-59">
                  <div className="product-5a">
                    <div className="frame-5b"></div>
                    <div className="frame-5c">
                      <div className="title-5d">
                        <span className="table-wood-5e">
                          Table en bois clair
                        </span>
                        <span className="time-5f">Il y a 5 minutes</span>
                      </div>
                      <div className="town-distance-60">
                        <span className="aix-provence-61">Aix-en-Provence</span>
                        <div className="frame-62">
                          <div className="solid-car-63">
                            <div className="solid-64"></div>
                          </div>
                          <span className="distance-65">25 km</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-66">
                    <div className="frame-67"></div>
                    <div className="frame-68">
                      <div className="title-69">
                        <span className="table-wood-6a">
                          Table en bois clair
                        </span>
                        <span className="time-6b">Il y a 5 minutes</span>
                      </div>
                      <div className="town-distance-6c">
                        <span className="aix-en-provence">Aix-en-Provence</span>
                        <div className="frame-6d">
                          <div className="solid-car-6e">
                            <div className="solid-6f"></div>
                          </div>
                          <span className="km">25 km</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="button" style={{ cursor: "pointer" }}>
                <span className="voir-tous-les-produits">
                  Voir tous les annonces
                </span>
              </div>
            </div>
            <div className="why">
              <div className="menu-b0">
                <span className="market-place-b1">
                  Vous êtes un professionnel ?
                </span>
              </div>
              <div className="arguments-b2">
                <div className="arguments-b3">
                  <div className="frame-b4">
                    <span className="reliability-security-b5">
                      Découvrez notre plateforme de livraison collaborative !{" "}
                    </span>
                    <span className="deliveries">
                      Frank une application mobile pour intégrer vos produits et
                      suivre les livraisons de manière intuitive. Nous vous
                      donnons un accès plus vaste à une manne de clients partout
                      en France.
                    </span>
                  </div>
                </div>
              </div>
              <div className="button-b6" style={{ cursor: "pointer" }}>
                <span className="discover">Découvrir</span>
              </div>
            </div>
          </div>
          {/* <div className="reco">
        <div className="vector-b7"></div>
        <div className="menu-b8">
          <span className="recommendation">Ils nous recommandent</span>
          <span className="delivery">
            Soyez livrés par nos Franks ou nos partenaires
          </span>
        </div>
        <div className="frame-b9">
          <div className="testimonials">
            <div className="text">
              <span className="jacques-c">Jacques C.</span>
              <span className="testimonial">
                Cela fait 3 ans que j’utilise Frank. je n’ai jamais eu de
                problèmes et cela ne me coûte vraiment pas cher !
              </span>
            </div>
            <div className="stars">
              <div className="star">
                <div className="icon-ba"></div>
              </div>
              <div className="star-bb">
                <div className="icon-bc"></div>
              </div>
              <div className="star-bd">
                <div className="icon-be"></div>
              </div>
              <div className="star-bf">
                <div className="icon-c0"></div>
              </div>
              <div className="star-c1">
                <div className="icon-c2"></div>
              </div>
            </div>
          </div>
          <div className="testimonials-c3">
            <div className="text-c4">
              <span className="henriette-d">Henriette D.</span>
              <span className="testimonial-c5">
                Lorem ipsum dolor sit amet consectetur. Fringilla in nibh nec
                tempor eu. Tempus lectus id platea adipiscing nisl risus nulla
                risus. Sit scelerisque pretium mattis adipiscing purus
                adipiscing metus vulputate. Nam felis sed tortor dolor risus sit
                arcu nunc id.
              </span>
            </div>
            <div className="stars-c6">
              <div className="star-c7">
                <div className="icon-c8"></div>
              </div>
              <div className="star-c9">
                <div className="icon-ca"></div>
              </div>
              <div className="star-cb">
                <div className="icon-cc"></div>
              </div>
              <div className="star-cd">
                <div className="icon-ce"></div>
              </div>
              <div className="star-cf">
                <div className="icon-d0"></div>
              </div>
            </div>
          </div>
          <div className="testimonials-d1">
            <div className="text-d2">
              <span className="jacques-c-d3">Jacques C.</span>
              <span className="cela-fait-ans">
                Cela fait 3 ans que j’utilise Frank. je n’ai jamais eu de
                problèmes et cela ne me coûte vraiment pas cher !
              </span>
            </div>
            <div className="stars-d4">
              <div className="line-star">
                <div className="icon-d5"></div>
              </div>
              <div className="line-star-d6">
                <div className="icon-d7"></div>
              </div>
              <div className="line-star-d8">
                <div className="icon-d9"></div>
              </div>
              <div className="line-star-da">
                <div className="icon-db"></div>
              </div>
              <div className="line-star-dc">
                <div className="icon-dd"></div>
              </div>
            </div>
          </div>
          <div className="testimonials-de">
            <div className="text-df">
              <span className="henriette-d-e0">Henriette D.</span>
              <span className="lorem-ipsum-dolor">
                Lorem ipsum dolor sit amet consectetur. Fringilla in nibh nec
                tempor eu. Tempus lectus id platea adipiscing nisl risus nulla
                risus. Sit scelerisque pretium mattis adipiscing purus
                adipiscing metus vulputate. Nam felis sed tortor dolor risus sit
                arcu nunc id.
              </span>
            </div>
            <div className="stars-e1">
              <div className="line-star-e2">
                <div className="icon-e3"></div>
              </div>
              <div className="line-star-e4">
                <div className="icon-e5"></div>
              </div>
              <div className="line-star-e6">
                <div className="icon-e7"></div>
              </div>
              <div className="line-star-e8">
                <div className="icon-e9"></div>
              </div>
              <div className="line-star-ea">
                <div className="icon-eb"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="button-ec">
          <span className="voir-tous-les-produits-ed">
            Voir tous les témoignages
          </span>
        </div>
      </div>
      <div className="news">
        <div className="image-ee"></div>
        <div className="image-ef"></div>
        <div className="image-f0"></div>
        <div className="image-f1"></div>
        <div className="image-f2"></div>
        <div className="image-f3"></div>
        <div className="image-f4"></div>
      </div> */}
          <div className="actus">
            <div className="menu-f5">
              <span
                className="achetez-vos-produits-f6"
                style={{ textAlign: "center" }}
              >
                Nos actus
              </span>
              <span className="text-56">
                Soyez livrés par nos Franks ou nos partenaires
              </span>
            </div>
            <div className="frame-f7">
              <div className="frame-f8">
                <div className="actu">
                  <div className="img-wrapper-blue">
                    <div className="pic-26"></div>
                  </div>
                  <div className="text-f9">
                    <div className="tilte">
                      <div className="frame-fa">
                        <div className="frame-fb">
                          <span className="du-nouveau-chez-frank">
                            Du nouveau chez Frank
                          </span>
                          <span className="il-y-a-minutes">
                            Il y a 5 minutes
                          </span>
                        </div>
                      </div>
                    </div>
                    <span className="lorem-ipsum-dolor-fc">
                      Lorem ipsum dolor sit amet consectetur. Sapien pharetra
                      adipiscing odio et bibendum duis. Auctor scelerisque
                      tellus tortor euismod...
                    </span>
                    <span
                      className="voir-lartcile"
                      style={{ cursor: "pointer" }}
                    >
                      Voir l’artcile
                    </span>
                  </div>
                </div>
                <div className="actu-fd">
                  <div className="img-wrapper-orange">
                    <div className="pic-27"></div>
                  </div>
                  <div className="text-fe">
                    <div className="tilte-ff">
                      <div className="frame-100">
                        <div className="frame-101">
                          <span className="du-nouveau-chez-frank-102">
                            Du nouveau chez Frank
                          </span>
                          <span className="il-y-a-minutes-103">
                            Il y a 5 minutes
                          </span>
                        </div>
                      </div>
                    </div>
                    <span className="lorem-ipsum-dolor-104">
                      Lorem ipsum dolor sit amet consectetur. Sapien pharetra
                      adipiscing odio et bibendum duis. Auctor scelerisque
                      tellus tortor euismod...
                    </span>
                    <span
                      className="voir-lartcile-105"
                      style={{ cursor: "pointer" }}
                    >
                      Voir l’artcile
                    </span>
                  </div>
                </div>
              </div>
              <div className="frame-106">
                <div className="actu-107">
                  <div className="img-wrapper-orange-108">
                    <div className="pic-28"></div>
                  </div>
                  <div className="text-109">
                    <div className="tilte-10a">
                      <div className="frame-10b">
                        <div className="frame-10c">
                          <span className="du-nouveau-chez-frank-10d">
                            Du nouveau chez Frank
                          </span>
                          <span className="il-y-a-minutes-10e">
                            Il y a 5 minutes
                          </span>
                        </div>
                      </div>
                    </div>
                    <span className="lorem-ipsum-dolor-10f">
                      Lorem ipsum dolor sit amet consectetur. Sapien pharetra
                      adipiscing odio et bibendum duis. Auctor scelerisque
                      tellus tortor euismod...
                    </span>
                    <span
                      className="voir-lartcile-110"
                      style={{ cursor: "pointer" }}
                    >
                      Voir l’artcile
                    </span>
                  </div>
                </div>
                <div className="actu-111">
                  <div className="img-wrapper-blue-112">
                    <div className="div"></div>
                  </div>
                  <div className="div-text">
                    <div className="div-title">
                      <div className="div-frame">
                        <div className="div-frame-113">
                          <span className="span-new-frank">
                            Du nouveau chez Frank
                          </span>
                          <span className="span-5-minutes">
                            Il y a 5 minutes
                          </span>
                        </div>
                      </div>
                    </div>
                    <span className="span-lorem-ipsum">
                      Lorem ipsum dolor sit amet consectetur. Sapien pharetra
                      adipiscing odio et bibendum duis. Auctor scelerisque
                      tellus tortor euismod...
                    </span>
                    <span
                      className="span-view-article"
                      style={{ cursor: "pointer" }}
                    >
                      Voir l’artcile
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="div-button" style={{ cursor: "pointer" }}>
              <span className="span-view-all-products">
                Voir toutes les actus
              </span>
            </div>
          </div>
          <div className="div-faq">
            <div className="div-menu">
              <span className="span-marketplace-questions">
                Des questions ?
              </span>
            </div>
            <div className="div-frame-114">
              <div className="div-frame-115">
                <div className="div-line"></div>

                <div className="div-frame-116">
                  <div style={{ width: "100%" }}>
                    <Accordion
                      elevation={0}
                      style={{
                        border: "0px",
                        background: "#fce6dd",
                        width: "100%",
                      }}
                    >
                      <AccordionSummary
                        style={{ border: "none", width: "100%" }}
                        expandIcon={
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                            }}
                          >
                            {" "}
                            <ExpandMore style={{ textAlign: "right" }} />{" "}
                          </div>
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                        className="span-what-is-frank"
                      >
                        Qu’est-ce que Frank ?
                      </AccordionSummary>
                      <AccordionDetails style={{ width: "100%" }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse malesuada lacus ex, sit amet blandit leo
                        lobortis eget.
                      </AccordionDetails>
                    </Accordion>
                
                  </div>
                </div>

                <div className="div-line-117"></div>
                <div className="div-frame-118">
                  <div style={{ width: "100%" }}>
                    <Accordion
                      elevation={0}
                      style={{
                        border: "0px",
                        background: "#fce6dd",
                        width: "100%",
                      }}
                    >
                      <AccordionSummary
                        style={{ border: "none", width: "100%" }}
                        expandIcon={
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                            }}
                          >
                            {" "}
                            <ExpandMore style={{ textAlign: "right" }} />{" "}
                          </div>
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                        className="span-what-is-frank"
                      >
                        Comment fonctionne la livraison ?
                      </AccordionSummary>
                      <AccordionDetails style={{ width: "100%" }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse malesuada lacus ex, sit amet blandit leo
                        lobortis eget.
                      </AccordionDetails>
                    </Accordion>
                 
                  </div>
                </div>
                <div className="div-line-11b"></div>
                <div className="div-frame-11c">
                  <div style={{ width: "100%" }}>
                    <Accordion
                      elevation={0}
                      style={{
                        border: "0px",
                        background: "#fce6dd",
                        width: "100%",
                      }}
                    >
                      <AccordionSummary
                        style={{ border: "none", width: "100%" }}
                        expandIcon={
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                            }}
                          >
                            {" "}
                            <ExpandMore style={{ textAlign: "right" }} />{" "}
                          </div>
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                        className="span-what-is-frank"
                      >
                        Que se passe-t-il si mon colis est perdu / volé ?
                      </AccordionSummary>
                      <AccordionDetails style={{ width: "100%" }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse malesuada lacus ex, sit amet blandit leo
                        lobortis eget.
                      </AccordionDetails>
                    </Accordion>
                 
                  </div>
                </div>
                <div className="div-line-11f"></div>
                <div className="div-frame-120">
                  <div style={{ width: "100%" }}>
                    <Accordion
                      elevation={0}
                      style={{
                        border: "0px",
                        background: "#fce6dd",
                        width: "100%",
                      }}
                    >
                      <AccordionSummary
                        style={{ border: "none", width: "100%" }}
                        expandIcon={
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                            }}
                          >
                            {" "}
                            <ExpandMore style={{ textAlign: "right" }} />{" "}
                          </div>
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                        className="span-what-is-frank"
                      >
                        Y a-t-il une limite de poids ?
                      </AccordionSummary>
                      <AccordionDetails style={{ width: "100%" }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse malesuada lacus ex, sit amet blandit leo
                        lobortis eget.
                      </AccordionDetails>
                    </Accordion>
                    {/* <span className="span-what-is-frank">Qu’est-ce que Frank ?</span>
              <div className="div-line-chevron-down">
                <div className="div-icon"></div>
              </div> */}
                  </div>
                </div>
                <div className="div-line-123"></div>
              </div>
            </div>
          </div>
          <div className="div-seo">
            <div className="div-background">
              <div className="div-vector"></div>
              <div className="div-menu-124">
                <span className="span-marketplace-info">
                  Tout savoir
                  <br />
                  sur Frank
                </span>
              </div>
              <div className="div-frame-125">
                <div className="frame-126">
                  <div className="tout-savoir">
                    <div className="solid-heart-circle">
                      <div className="solid-127"></div>
                    </div>
                    <span className="redirection">Redirection 1</span>
                  </div>
                  <div className="tout-savoir-128">
                    <div className="solid-heart-circle-129">
                      <div className="solid-12a"></div>
                    </div>
                    <span className="redirection-12b">Redirection 1</span>
                  </div>
                  <div className="tout-savoir-12c">
                    <div className="solid-heart-circle-12d">
                      <div className="solid-12e"></div>
                    </div>
                    <span className="redirection-12f">Redirection 1</span>
                  </div>
                  <div className="tout-savoir-130">
                    <div className="solid-heart-circle-131">
                      <div className="solid-132"></div>
                    </div>
                    <span className="redirection-133">Redirection 1</span>
                  </div>
                </div>
                <div className="frame-134">
                  <div className="tout-savoir-135">
                    <div className="solid-heart-circle-136">
                      <div className="solid-137"></div>
                    </div>
                    <span className="redirection-138">Redirection 1</span>
                  </div>
                  <div className="frame-139">
                    <div className="solid-heart-circle-13a">
                      <div className="solid-13b"></div>
                    </div>
                    <span className="redirection-13c">Redirection 1</span>
                  </div>
                  <div className="frame-13d">
                    <div className="solid-heart-circle-13e">
                      <div className="solid-13f"></div>
                    </div>
                    <span className="redirection-140">Redirection 1</span>
                  </div>
                  <div className="frame-141">
                    <div className="solid-heart-circle-142">
                      <div className="solid-143"></div>
                    </div>
                    <span className="redirection-144">Redirection 1</span>
                  </div>
                </div>
              </div>
              <div className="frame-145" style={{ cursor: "pointer" }}>
                <span className="voir-toutes-les-categories">
                  Voir toutes les catégories
                </span>
                <div
                  className="line-chevron-down-146"
                  style={{ cursor: "pointer" }}
                >
                  <div className="icon-147" style={{ cursor: "pointer" }}></div>
                </div>
              </div>
            </div>
          </div>
          <div className="frame-148">
            <div className="background">
              <div className="vector-149"></div>
              <div className="frame-14a">
                <div className="screen-png"></div>
              </div>
              <div className="container">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    width: "100%",
                    paddingRight: "40px",
                  }}
                >
                  <div className="frame-172">
                    <div className="line-arrow-right-173">
                      <div className="icon-174"></div>
                    </div>
                  </div>
                </div>

                <div className="frame-14b" style={{ flex: 1 }}>
                  <span className="download-app">DOWNLOAD APP</span>
                  <span className="get-frank-mobile-application">
                    Get Frank Mobile
                    <br />
                    Application
                  </span>
                </div>

                <span className="download-customer-app">
                  Download Customer App and Transporter App for free.
                </span>
                <div className="frame-14c">
                  <div className="link">
                    <div className="google-play-store-badge-14d"></div>
                  </div>
                  <div className="link-14e">
                    {/* <div className="app-store-badge">
                      <div className="app-store-badge-fill">
                        <div className="app-store-badge-14f">
                          <div className="group">
                            <div className="vector-150"></div>
                            <div className="vector-151"></div>
                            <div className="group-152">
                              <div className="group-153">
                                <div className="vector-154"></div>
                                <div className="vector-155"></div>
                              </div>
                            </div>
                            <div className="group-156">
                              <div className="group-157">
                                <div className="flex-row-d">
                                  <div className="vector-158"></div>
                                  <div className="vector-159"></div>
                                  <div className="regroup">
                                    <div className="vector-15a"></div>
                                    <div className="vector-15b"></div>
                                  </div>
                                </div>
                                <div className="flex-row">
                                  <div className="vector-15c"></div>
                                  <div className="regroup-15d">
                                    <div className="vector-15e"></div>
                                    <div className="vector-15f"></div>
                                  </div>
                                  <div className="vector-160"></div>
                                  <div className="vector-161"></div>
                                  <div className="vector-162"></div>
                                  <div className="vector-163"></div>
                                  <div className="vector-164"></div>
                                </div>
                                <div className="vector-165"></div>
                              </div>
                            </div>
                            <div className="group-166">
                              <div className="vector-167"></div>
                              <div className="vector-168"></div>
                              <div className="vector-169"></div>
                              <div className="vector-16a"></div>
                              <div className="vector-16b"></div>
                              <div className="vector-16c"></div>
                              <div className="vector-16d"></div>
                              <div className="vector-16e"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <AppStoreFR />
                  </div>
                  <div className="link-16f">
                    <div className="huawei-appgallery-badge">
                      <div className="huawei-appgallery-badge-fill">
                        <div className="huawei-appgallery-badge-170">
                          <div className="group-171"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="frame-175">
            <div className="frame-176">
              <div className="tracking">
                <div className="icon-svg">
                  <div className="icon-svg-fill">
                    <div className="icon-svg-177">
                      <div className="mask-group-178">
                        <div className="group-179">
                          <div className="vector-17a"></div>
                        </div>
                      </div>
                      <div className="vector-17b"></div>
                      <div className="vector-17c"></div>
                    </div>
                  </div>
                </div>
                <div className="frame-17d">
                  <span className="heading-tracking">Tracking</span>
                  <span className="click-here-to-track">
                    Click here to track the status
                    <br />
                    of your order.
                  </span>
                </div>
              </div>
              <div className="support">
                <div className="icon-svg-17e">
                  <div className="icon-svg-fill-17f">
                    <div className="icon-svg-180"></div>
                  </div>
                </div>
                <div className="frame-181">
                  <span className="heading-support">Support</span>
                  <span className="question-inquiry">
                    For any question or inquiry, call at
                  </span>
                  <div className="link-182">
                    <span className="phone-number">
                      <a
                        href="tel:+33 1 42 764040"
                        style={{ textDecoration: "none", color: "#000" }}
                      >
                        {" "}
                        +33 1 42 764040
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer">
              <div className="flex-column-c">
                <div className="shape-png"></div>
                <div className="shape-png-183"></div>
                <div className="container-184">
                  <Grid
                    container
                    spacing={2}
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "0px 0px 0px 70px",
                    }}
                  >
                    <Grid
                      xs={12}
                      md={3}
                      item
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingLeft: "70px",
                      }}
                    >
                      <div className="logo">
                        <div className="link-185" style={{ cursor: "pointer" }}>
                          <div className="white-logo-svg">
                            <div className="white-logo-fill">
                              <div className="white-logo-svg-186">
                                <div className="group-187">
                                  <div className="layer"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="list">
                          <div
                            className="item-link"
                            style={{ cursor: "pointer" }}
                          >
                            <div className="symbol"></div>
                          </div>
                          <div
                            className="item-link-188"
                            style={{ cursor: "pointer" }}
                          >
                            <div className="symbol-189"></div>
                          </div>
                          <div
                            className="item-link-18a"
                            style={{ cursor: "pointer" }}
                          >
                            <div className="symbol-18b"></div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid xs={12} md={3} item>
                      <div className="list-18c">
                        <div
                          className="item-link-18d"
                          style={{ cursor: "pointer" }}
                        >
                          <span className="home">Home</span>
                        </div>
                        <div
                          className="item-link-18e"
                          style={{ cursor: "pointer" }}
                        >
                          <span className="my-order-status">
                            My Order Status
                          </span>
                        </div>
                        <div
                          className="item-link-18f"
                          style={{ cursor: "pointer" }}
                        >
                          <span className="support-center">Support Center</span>
                        </div>
                        <div
                          className="item-link-190"
                          style={{ cursor: "pointer" }}
                        >
                          <span className="customer-help">Customer Help</span>
                        </div>
                      </div>
                    </Grid>
                    <Grid xs={12} md={3} item>
                      <div className="list-191">
                        <div
                          className="item-link-192"
                          style={{ cursor: "pointer" }}
                        >
                          <span className="rider">Rider</span>
                        </div>
                        <div
                          className="item-link-193"
                          style={{ cursor: "pointer" }}
                        >
                          <span className="air-rider">Air Rider</span>
                        </div>
                        <div
                          className="item-link-194"
                          style={{ cursor: "pointer" }}
                        >
                          <span className="fleet-manager">Fleet Manager</span>
                        </div>
                        <div
                          className="item-link-195"
                          style={{ cursor: "pointer" }}
                        >
                          <span className="sub-rider">Sub-Rider</span>
                        </div>
                      </div>
                    </Grid>
                    <Grid xs={12} md={3} item>
                      <div className="list-196">
                        <div
                          className="item-link-197"
                          style={{ cursor: "pointer" }}
                        >
                          <span className="e-merchant-store">
                            E-merchant Store
                          </span>
                        </div>
                        <div
                          className="item-link-198"
                          style={{ cursor: "pointer" }}
                        >
                          <span className="space-store">Space Store</span>
                        </div>
                        <div
                          className="item-link-199"
                          style={{ cursor: "pointer" }}
                        >
                          <span className="d2c-store">D2C Store</span>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <span className="frank" style={{ cursor: "pointer" }}>
                  Frank © 2023
                </span>
              </div>
              <div className="shape-png-19a"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="mobile-screen">
        <MobileView />
      </div>
    </>
  );
}

export default LandingPage;
