import PropTypes from "prop-types";
import LandingPage from "../views/LandingPage";
import LoginPage from "../views/Auth";
import SignUp from "../views/SignUp";
import Profile from "../views/Profile";
import Blogs from "../views/Blogs";
import SendParcel from "../views/SendParcel";
 
// ==============================|| MAIN ROUTING ||============================== //

export function MainAppRoutes({ classes }: any) {
  const MainRoutesBefore = {
    path: "/",
    // element: <LandingPage  />,
    children: [
      {
        path: "",
        element: <LandingPage {...classes} />,
      },
      {
        path: "login",
        element: <LoginPage {...classes} />,
      },
      {
        path: "sign-up",
        element: <SignUp {...classes} />,
      },
      {
        path: "profile",
        element: <Profile {...classes} />,
      },
      {
        path: "blogs",
        element: <Blogs {...classes} />,
      },
      {
        path: "send-parcel",
        element: <SendParcel {...classes} />,
      },
      
    ],
  };

  return MainRoutesBefore;
}

MainAppRoutes.propTypes = {
  classes: PropTypes.object,
};
