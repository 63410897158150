import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  CardMedia,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import Header from "../../UiComponent/Header";
import {
  AppStoreFR,
  ArticleImageOne,
  ArticleImageThree,
  ArticleImageTwo,
  EnvelopeIcon,
  FacebookBlackandWhiteIcon,
  FrankIcon,
  MediumIcon,
  SendParcelTopIcon,
} from "../../assets/images/FrankIcons";
import { defaultLabelColors, SHIPMENT_TYPE } from "../../utils/constants";
import verticalBanner from "../../assets/images/FrankIcons/vertical-banner.png";
import classNames from "classnames";
import playSore from "../../assets/images/FrankIcons/PlayStore-btn.png";
import JobCard from "../../UiComponent/JobCard";
import apiServices from "../../services/RequestHandler";
import bottomBanner from "../../assets/images/FrankIcons/bottom-banner.png";
import coloredDivOne from "../../assets/images/FrankIcons/colored-div-one.png";
import coloredDivTwo from "../../assets/images/FrankIcons/colored-div-two.png";
import coloredDivThree from "../../assets/images/FrankIcons/colored-div-three.png";
import { fontWeight } from "@mui/system";
import { ExpandMore } from "@mui/icons-material";
import footerBanner from "../../assets/images/FrankIcons/footer-banner-anounces.png";
import CustomButton from "../../UiComponent/CustomButton";
import DraggableDialog from "../../UiComponent/DraggableDialog";

function SendParcel(props: any) {
  const {classes} = props;
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {}
  );

  useEffect(() => {
    handleGetRecentTransactions();
  }, []);

  const handleGetRecentTransactions = async () => {
    try {
      let resp = await apiServices.getFromApi(
        "c2c-shipments/get-recent-by-customer",
        ""
      );
      console.log("resp", resp);
      setState({
        recentTransactions: resp?.data,
      });
    } catch (error) {}
  };

  const { recentTransactions } = state;
  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12} item>
          <Header />
        </Grid>
      </Grid>

      <div style={{ padding: "0px 100px 0px 100px" }}>
        <Grid container spacing={2}>
          <Grid xs={12} item>
            <p className="heading-one">Envoyer un colis</p>
          </Grid>

          <Grid xs={12} md={9.5} item style={{}}>
            <Grid container style={{ columnGap: "20px" }}>
              <Grid xs={12} item>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <SendParcelTopIcon />
                  <div style={{ flex: 1 }}>
                    <p className="heading-two" style={{ fontSize: "28px" }}>
                      Faites transporter un colis par un{" "}
                      <span style={{ color: "#3026f1" }}> particulier !</span>
                    </p>
                    <p
                      className="paragraph-text"
                      style={{ marginTop: "-20px" }}
                    >
                      Gagnez de l'argent en transportant des colis entre
                      particuliers
                    </p>
                  </div>
                </div>
              </Grid>

              <Grid xs={12} md={10} item>
                <p className="heading-two" style={{ fontSize: "22px" }}>
                  Taille du colis
                </p>
              </Grid>
              {SHIPMENT_TYPE?.map((i: any) => {
                return (
                  <>
                    <div
                      style={{
                        borderRadius: "24px",
                        background: "#fff",
                        width: "18.5%",
                        flexDirection: "column",
                        padding: 20,
                        fontFamily: "Raleway-Variable",
                        gap: "20px",
                        cursor: "pointer",
                      }}
                      className="display-flex"
                    >
                      <div>
                        {i?.icon === "envelop" ? (
                          <EnvelopeIcon />
                        ) : i?.icon === "medium" ? (
                          <MediumIcon />
                        ) : i?.icon === "large" ? (
                          <MediumIcon />
                        ) : i?.icon === "extra_large" ? (
                          <MediumIcon />
                        ) : null}
                      </div>
                      <div style={{ fontWeight: "800", textAlign: "center" }}>
                        {i?.label}
                      </div>
                      <div style={{ textAlign: "center" }}>{i?.data} </div>
                    </div>
                  </>
                );
              })}
            </Grid>
          </Grid>

          <Grid md={2.5} item>
            <div
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img
                src={verticalBanner}
                style={{
                  position: "absolute",
                  width: "100%",
                  top: 0,
                  objectFit: "contain",
                  zIndex: -1,
                }}
                alt="banner"
              />
              <div
                style={{
                  paddingTop: 20,
                  rowGap: 20,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <FrankIcon fill={"#fff"} height={70} />

                <p
                  className="heading-two"
                  style={{
                    fontSize: "28px",
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  <span style={{ fontSize: "24px", fontWeight: "500" }}>
                    Télécharger
                  </span>
                  <br />
                  notre application mobile
                </p>

                <div
                  className="display-flex"
                  style={{ flexDirection: "column", gap: "10px" }}
                >
                  <a
                    href="https://apps.apple.com/app/frank/id6502341979"
                    target="_blank"
                  >
                    {" "}
                    <AppStoreFR height={50} />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.frankc2c"
                    target="_blank"
                  >
                    {" "}
                    <img src={playSore} height={46} />
                  </a>
                </div>
              </div>
            </div>
          </Grid>
          <Grid xs={12} md={10} item>
            <p className="heading-two" style={{ fontSize: "22px" }}>
              Transactions récentes
            </p>
          </Grid>

          <Grid
            xs={12}
            md={10}
            item
            style={{ display: "flex", columnGap: "20px" }}
          >
            {recentTransactions?.map((i: any, index: any) => {
              return (
                <JobCard
                  jobTitle={i?.shipment_items?.[0]?.prod_name}
                  jobStatus={i?.ship_status}
                  originLocation={`${i?.pickup?.city},${i?.pickup?.country}`}
                  destinationLocation={`${i?.dropoff?.city},${i?.dropoff?.country}`}
                />
              );
            })}
          </Grid>

          <Grid xs={12} md={9} item style={{ marginTop: "20px" }}>
            <div
              //   style={{
              //     position: "relative",
              //     display: "flex",
              //     alignItems: "flex-end",
              //     flexDirection: "column",
              //     justifyContent:'center',
              //     paddingTop:'10px',
              //     width: "96%",
              //   }}
              style={{ width: "96%" }}
            >
              <img
                src={bottomBanner}
                style={{
                  width: "100%",
                  top: 0,
                  objectFit: "contain",
                }}
                alt=""
              />
              {/* <div
                style={{
                  display: "flex",
                  gap:'20px',
                  justifyContent:'center',
                  paddingTop:'5px'

                }}
              >
                <div>
                  <p className="paragraph-text" style={{ fontSize: "22px",right:0 }}>
                    Qu’est-ce
                  </p>
                  <FrankIcon
                    fill={defaultLabelColors?.main_orange}
                    height={60}
                  />
                </div>
                <div style={{ position: "relative",height:'100%',width:'230px' }}>
                  <img src={coloredDivOne} alt=""  style={{objectFit:'contain',position:'absolute' ,width:'100%'}} />
                  <p style={{position:'absolute',fontFamily:'Raleway-Variable',fontWeight:'700',textAlign:"center",zIndex:111,paddingLeft:'45px',paddingTop:'20px'}}>
                  Insérez
                  <br/>
                <span style={{fontWeight:'500'}} >  l’adresse d’envoi</span>
                  </p>
                </div>
                <div style={{ position: "relative",height:'100%',width:'230px' }}>
                  <img src={coloredDivTwo} alt=""  style={{objectFit:'contain' ,width:'100%'}} />
                </div>
                <div style={{ position: "relative",height:'100%',width:'230px',marginRight:'20px' }}>
                  <img src={coloredDivThree} alt="" style={{objectFit:'contain' ,width:'100%'}} />
                </div>
              </div> */}
            </div>
          </Grid>

          <Grid xs={12} item>
            <p className="heading-two">Articles</p>
          </Grid>
          <Grid xs={12} item style={{ display: "flex", columnGap: "90px" }}>
            <Card sx={{ maxWidth: 345, borderRadius: 4 }}>
              <ArticleImageOne width={345} height={140} fill="#0000ff" />
              <CardContent>
                <Typography variant="h6" component="div" fontWeight="bold">
                  Du nouveau chez Frank
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ marginTop: 1 }}
                >
                  Il y a 5 minutes
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ marginTop: 1 }}
                >
                  Lorem ipsum dolor sit amet consectetur. Sapien pharetra
                  adipiscing odio et bibendum duis. Auctor scelerisque tellus
                  tortor euismod...
                </Typography>
                <Button
                  size="small"
                  sx={{
                    color: "#ff6d00",
                    textTransform: "none",
                    marginTop: 2,
                    fontWeight: "bold",
                  }}
                >
                  Voir l'article
                </Button>
              </CardContent>
            </Card>

            <Card sx={{ maxWidth: 345, borderRadius: 4 }}>
              <ArticleImageTwo width={345} height={140} fill="#0000ff" />
              <CardContent>
                <Typography variant="h6" component="div" fontWeight="bold">
                  Du nouveau chez Frank
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ marginTop: 1 }}
                >
                  Il y a 5 minutes
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ marginTop: 1 }}
                >
                  Lorem ipsum dolor sit amet consectetur. Sapien pharetra
                  adipiscing odio et bibendum duis. Auctor scelerisque tellus
                  tortor euismod...
                </Typography>
                <Button
                  size="small"
                  sx={{
                    color: "#ff6d00",
                    textTransform: "none",
                    marginTop: 2,
                    fontWeight: "bold",
                  }}
                >
                  Voir l'article
                </Button>
              </CardContent>
            </Card>

            <Card sx={{ maxWidth: 345, borderRadius: 4 }}>
              <ArticleImageThree width={345} height={140} fill="#0000ff" />
              <CardContent>
                <Typography variant="h6" component="div" fontWeight="bold">
                  Du nouveau chez Frank
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ marginTop: 1 }}
                >
                  Il y a 5 minutes
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ marginTop: 1 }}
                >
                  Lorem ipsum dolor sit amet consectetur. Sapien pharetra
                  adipiscing odio et bibendum duis. Auctor scelerisque tellus
                  tortor euismod...
                </Typography>
                <Button
                  size="small"
                  sx={{
                    color: "#ff6d00",
                    textTransform: "none",
                    marginTop: 2,
                    fontWeight: "bold",
                  }}
                >
                  Voir l'article
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid xs={12} item>
            <p className="heading-two">FAQ</p>
          </Grid>

          <Grid xs={9} item>
            <div className="div-frame-1-1-4" style={{ padding: "0px" }}>
              <div className="div-frame-115">
                <div className="div-frame-116">
                  <div style={{ width: "100%" }}>
                    <Accordion
                      elevation={0}
                      style={{
                        border: "0px",
                        background: "#fce6dd",
                        width: "100%",
                      }}
                    >
                      <AccordionSummary
                        style={{ border: "none", width: "100%" }}
                        expandIcon={
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                            }}
                          >
                            {" "}
                            <ExpandMore style={{ textAlign: "right" }} />{" "}
                          </div>
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                        className="span-what-is-frank"
                      >
                        Qu’est-ce que Frank ?
                      </AccordionSummary>
                      <AccordionDetails style={{ width: "100%" }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse malesuada lacus ex, sit amet blandit leo
                        lobortis eget.
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>

                <div className="div-line-117"></div>
                <div className="div-frame-118">
                  <div style={{ width: "100%" }}>
                    <Accordion
                      elevation={0}
                      style={{
                        border: "0px",
                        background: "#fce6dd",
                        width: "100%",
                      }}
                    >
                      <AccordionSummary
                        style={{ border: "none", width: "100%" }}
                        expandIcon={
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                            }}
                          >
                            {" "}
                            <ExpandMore style={{ textAlign: "right" }} />{" "}
                          </div>
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                        className="span-what-is-frank"
                      >
                        Comment fonctionne la livraison ?
                      </AccordionSummary>
                      <AccordionDetails style={{ width: "100%" }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse malesuada lacus ex, sit amet blandit leo
                        lobortis eget.
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
                <div className="div-line-11b"></div>
                <div className="div-frame-11c">
                  <div style={{ width: "100%" }}>
                    <Accordion
                      elevation={0}
                      style={{
                        border: "0px",
                        background: "#fce6dd",
                        width: "100%",
                      }}
                    >
                      <AccordionSummary
                        style={{ border: "none", width: "100%" }}
                        expandIcon={
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                            }}
                          >
                            {" "}
                            <ExpandMore style={{ textAlign: "right" }} />{" "}
                          </div>
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                        className="span-what-is-frank"
                      >
                        Que se passe-t-il si mon colis est perdu / volé ?
                      </AccordionSummary>
                      <AccordionDetails style={{ width: "100%" }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse malesuada lacus ex, sit amet blandit leo
                        lobortis eget.
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
                <div className="div-line-11f"></div>
                <div className="div-frame-120">
                  <div style={{ width: "100%" }}>
                    <Accordion
                      elevation={0}
                      style={{
                        border: "0px",
                        background: "#fce6dd",
                        width: "100%",
                      }}
                    >
                      <AccordionSummary
                        style={{ border: "none", width: "100%" }}
                        expandIcon={
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                            }}
                          >
                            {" "}
                            <ExpandMore style={{ textAlign: "right" }} />{" "}
                          </div>
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                        className="span-what-is-frank"
                      >
                        Y a-t-il une limite de poids ?
                      </AccordionSummary>
                      <AccordionDetails style={{ width: "100%" }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse malesuada lacus ex, sit amet blandit leo
                        lobortis eget.
                      </AccordionDetails>
                    </Accordion>
                    {/* <span className="span-what-is-frank">Qu’est-ce que Frank ?</span>
              <div className="div-line-chevron-down">
                <div className="div-icon"></div>
              </div> */}
                  </div>
                </div>
                <div className="div-line-123"></div>
              </div>
            </div>
          </Grid>

          <Grid xs={12} md={10} item>
            <div
              style={{
                position: "relative",
                flexDirection: "column",
                padding: "20px",
              }}
              className="display-flex"
            >
              <img
                src={footerBanner}
                style={{ position: "absolute", width: "100%", height: "100%" }}
                alt=""
              />
              <p
                className="heading-two"
                style={{ fontSize: "32px", color: "#fff", zIndex: 111 }}
              >
                Être notifié plus rapidement ?
              </p>
              <p
                className="paragraph-text"
                style={{ textAlign: "center", zIndex: 111 }}
              >
                Lorem ipsum dolor sit amet consectetur. Sapien pharetra
                adipiscing odio et bibendum duis. Auctor scelerisque tellus
                tortor euismod...
              </p>

              <CustomButton
                value={"Télécharger l’application mobile"}
                style={{
                  background: defaultLabelColors.main_blue,
                  color: "white",
                  borderRadius: "12px",
                  padding: "10px",
                }}
              />
            </div>
          </Grid>
        </Grid>
      </div>

      <DraggableDialog
        maxWidth={"md"}
        open={state?.openOTPDialog}
        handleClose={() => {
          setState({ openOTPDialog: false });
        }}
        dialog_title={"Vérification OTP"}
        classes={classes}
        dialogBody={
          <DialogContent
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexDirection: "column",
            }}
            dividers
          >
            
           
               
      
               
                 
                  
         
 


         

         


          </DialogContent>
        }
      />


    </>
  );
}

export default SendParcel;
