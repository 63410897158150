/*
  Created By Muhammad Hassaan
  Date 15 April 2022
*/
import classNames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { styled } from "@mui/system";


const CustomTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
      fontFamily:'Raleway-Variable'
    },
  },
});
export default function InputField(props: any) {
  const { startIcon, input_label, input_value, input_name, input_style,placeHolder,onchange,input_type,endIicon } =
    props;

  return (
    <CustomTextField
    type={input_type}
    placeholder={placeHolder}
      label={input_label}
      onChange={onchange}
      name={input_name}
      style={input_style}
      id="outlined-start-adornment"
      sx={{ m: 1, width: "25ch",border:'none',outline:'',
        fontFamily: "Raleway-Variable",
        '& .MuiInputBase-input': {
          fontFamily: "Raleway-Variable",
        },
        '& .MuiInputLabel-root': {
          fontFamily: "Raleway-Variable",
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            fontFamily: "Raleway-Variable",
          },

       }}
      }
      value={input_value}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{startIcon}</InputAdornment>
        ),
        endAdornment:(
          <InputAdornment position="end">{endIicon}</InputAdornment>

        )
        
      }}
    />
  );
}
InputField.defaultProps = {
  startIcon: "",
  endIicon:"",
  input_label: "",
  input_value: "",
  input_name: "",
  placeHolder:"",
  onchange:{},
  input_type:"",
  input_style:{
    fontFamily:'Raleway-Variable'
  }


};

InputField.propTypes = {
  startIcon: PropTypes.any,
  endIicon:PropTypes.any,
  input_label: PropTypes.string,
  input_value: PropTypes.any,
  input_name: PropTypes.any,
  input_style: PropTypes.any,
  placeHolder:PropTypes.any,
  onchange:PropTypes.func,
  input_type:PropTypes.string
};
