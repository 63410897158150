import {
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Switch,
  Tab,
  Tabs,
} from "@mui/material";
import {
  borderRadius,
  Box,
  color,
  fontFamily,
  fontSize,
  height,
  styled,
} from "@mui/system";
import React, { useEffect } from "react";
import Header from "../../UiComponent/Header";
import { CameraAlt } from "@mui/icons-material";
import { Rating } from "react-simple-star-rating";
import InputField from "../../UiComponent/TextFeild";
import FlagInputField from "../../UiComponent/FlagInput";
import CustomButton from "../../UiComponent/CustomButton";
import { templeteData } from "../../utils/constants";
import { RefrestIcon } from "../../assets/images/FrankIcons";
import orangeBackground from "../../assets/images/FrankIcons/orange-background.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Store";
import { getProfileInfo } from "../../Store/ProfileReducer";
import apiServices from "../../services/RequestHandler";
const CustomSwitch = styled(Switch)({
  "& .Mui-checked": {
    color: "#ee6931", // Active color
  },
  "& .Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#ee6931", // Track color when checked
  },
});

const CustomTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "#3026f1", // Indicator color
  },
});

const CustomTab = styled(Tab)({
  fontFamily: "Raleway-Variable",
  fontWeight: 700,
  fontSize: "16px",
  textTransform: "capitalize",
  color: "#626175", // Inactive tab text color
  "&.Mui-selected": {
    color: "#3026f1", // Active tab text color
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Profile(props: any) {
  const { classes } = props;
  const [value, setValue] = React.useState(0);
  // const dispatch = useDispatch<any>();
  // const profileInfoSlice = useSelector((state: RootState) => state.profileInfo);
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {}
  );

  useEffect(() => {
    handleGetProfileInfo();
  }, []);

  const handleGetProfileInfo = async () => {
    try {
      let resp = await apiServices.getFromApi("c2c/me", "");
      console.log("resp", resp);

      setState({
        title: resp?.data?.title,
        first_name: resp?.data?.first_name,
        surname: resp?.data?.surname,
        phone_code: resp?.data?.phone_code,
        phone: resp?.data?.phone,
        email: resp?.data?.email,
        date_of_birth: resp?.data?.date_of_birth,
      });
    } catch (error) {}
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleChangeImage = async () => {
    try {
    } catch (error) {}
  };
  const handleChangeMobileCode = (dialcode: any, codeName: any) => {
    try {
      setState({
        [codeName]: dialcode,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleChangeMobile = (event: any) => {
    try {
      setState({
        [event.target.name]: event.target.value,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const enableFlagInput = (state: any, value: any) => {
    try {
      setState({
        [state]: value,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Grid container spacing={2} item>
        <Grid xs={12} item>
          <Header />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        className="display-flex"
        style={{ padding: "30px 50px 30px 50px" }}
      >
        <Grid xs={10} item>
          <Box sx={{ width: "100%" }}>
            <Box>
              <CustomTabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <CustomTab label="Profil" />
                <CustomTab label="Porte monnaie" />
                <CustomTab label="Evaluations" />
              </CustomTabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Grid
                container
                spacing={2}
                style={{
                  background: "#fff",
                  borderRadius: "16px",
                  padding: "20px",
                }}
              >
                <Grid xs={12} item>
                  <p className="profile-headings">Mon profil</p>
                </Grid>

                <Grid xs={12} md={4} lg={2} item className="display-flex">
                  <div>
                    <Box
                      sx={{
                        position: "relative",
                        width: 200,
                        height: 200,
                        borderRadius: "50%",
                        overflow: "hidden",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: 3,
                      }}
                    >
                      <img
                        src="https://d2v5dzhdg4zhx3.cloudfront.net/web-assets/images/storypages/short/linkedin-profile-picture-maker/dummy_image/thumb/001.webp" // replace with your image path
                        alt="Profile"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                      <IconButton
                        onClick={handleChangeImage}
                        sx={{
                          position: "absolute",
                          bottom: 10,
                          right: 10,
                          backgroundColor: "white",
                          borderRadius: "50%",
                          boxShadow: 3,
                          "&:hover": {
                            backgroundColor: "#f0f0f0",
                          },
                        }}
                      >
                        <CameraAlt sx={{ color: "#3026f1" }} />
                      </IconButton>
                    </Box>
                    <div style={{ padding: "10px 0px 10px 0px" }}>
                      <Rating
                        disableFillHover
                        initialValue={3}
                        allowHover={false}
                        size={40}
                      />
                      <p
                        style={{
                          textAlign: "left",
                          fontSize: "16px",
                          fontWeight: "700",
                          fontFamily: "Raleway-Variable",
                        }}
                      >
                        32 avis
                      </p>
                    </div>
                  </div>
                </Grid>
                <Grid xs={12} md={4} lg={5} item>
                  <Grid container spacing={1}>
                    <Grid xs={12} item>
                      <FormControl
                        size="small"
                        style={{
                          background: "#F4F3F6",
                          width: "100%",
                          borderRadius: "16px",
                          padding: "10px 0px 10px 0px",
                          marginLeft: "10px",
                          fontFamily: "Raleway-Variable",
                        }}
                        className="display-flex"
                      >
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            control={<Radio size="small" value="Male" />}
                            name="title"
                            label={"Madame"}
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontFamily: "Raleway-Variable",
                                fontSize: "small",
                              },
                            }}
                            onClick={() => {
                              setState({ title: "Male" });
                            }}
                          />
                          <FormControlLabel
                            control={
                              <Radio
                                value="Female"
                                size="small"
                                name="title"
                                onClick={() => {
                                  setState({ title: "Female" });
                                }}
                              />
                            }
                            label={"Monsieur"}
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontFamily: "Raleway-Variable",
                                fontSize: "small",
                              },
                            }}
                          />
                          <FormControlLabel
                            onClick={() => {
                              setState({ title: "Others" });
                            }}
                            control={
                              <Radio size="small" value="Other" name="title" />
                            }
                            label={"Ne souhaite pas communiquer"}
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontFamily: "Raleway-Variable",
                                fontSize: "small",
                              },
                            }}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid xs={12} item>
                      <InputField
                        placeHolder={"Prénom"}
                        input_value={state?.first_name}
                        input_name={"first_name"}
                        input_style={{
                          background: "#F4F3F6",
                          width: "100%",
                          borderRadius: "16px",
                          border: "none",
                        }}
                        // onchange={onInputChange}
                      />
                    </Grid>
                    <Grid xs={12} item>
                      <InputField
                        placeHolder={"Nom"}
                        input_value={state?.surname}
                        input_name={"surname"}
                        input_style={{
                          background: "#F4F3F6",
                          width: "100%",
                          borderRadius: "16px",
                          border: "none",
                        }}
                        // onchange={onInputChange}
                      />
                    </Grid>
                    <Grid xs={12} item>
                      <CustomButton
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#fff",
                          fontFamily: "Raleway-Variable",
                          background: "#ee6931",
                          border: "none",
                          borderRadius: "12px",
                          padding: "10px 40px 10px 40px",
                          marginLeft: "10px",
                        }}
                        value={"Enregistrer"}
                        // onClick={handleSubmitCredentials}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={12} md={4} lg={5} item>
                  <Grid container spacing={1}>
                    <Grid xs={12} item style={{ marginLeft: "10px" }}>
                      <FlagInputField
                        divStyle={{
                          background: "#F4F3F6",
                          borderRadius: "16px",
                          border: "none",
                          padding: "8px",
                          width: "98%",
                        }}
                        classes={classes}
                        inputStyle={{ background: "#F4F3F6" }}
                        required={false}
                        name={"phone"}
                        codeName={"phone_code"}
                        value={state?.phone || ""}
                        countryCode={state?.phone_code || "+92"}
                        handleChangeMobileCode={(dialcode, codeName) =>
                          handleChangeMobileCode(dialcode, codeName)
                        }
                        handleChangeMobile={(event) =>
                          handleChangeMobile(event)
                        }
                        enableFlagInput={enableFlagInput}
                      />
                    </Grid>
                    <Grid xs={12} item>
                      <InputField
                        placeHolder={"example@gmail.com"}
                        input_value={state?.email}
                        input_name={"email"}
                        input_style={{
                          background: "#F4F3F6",
                          width: "100%",
                          borderRadius: "16px",
                          border: "none",
                        }}
                        // onchange={onInputChange}
                      />
                    </Grid>
                    <Grid xs={12} item>
                      <InputField
                        placeHolder={"31/01/2001"}
                        input_value={state?.date_of_birth}
                        input_name={"date_of-birth"}
                        input_style={{
                          background: "#F4F3F6",
                          width: "100%",
                          borderRadius: "16px",
                          border: "none",
                        }}
                        // onchange={onInputChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                style={{
                  background: "#fff",
                  borderRadius: "16px",
                  padding: "20px",
                  marginTop: "20px",
                }}
              >
                <Grid xs={12} item>
                  <p className="profile-headings">Vérification d’identité</p>
                </Grid>

                <Grid xs={12} item>
                  <p className="profile-text">
                    Lorem ipsum dolor sit amet consectetur. Tincidunt vitae at
                    aliquam metus mattis. Integer lectus aenean purus pretium
                    pellentesque sed.
                  </p>
                  <div>
                    <p className="profile-text">
                      1. Lacus mattis placerat sapien nulla pulvinar a et massa.
                      Est consectetur donec id quisque est facilisis quis massa.
                    </p>
                    <p className="profile-text">
                      2. Lacus mattis placerat sapien nulla pulvinar a et massa.
                      Est consectetur donec id quisque est facilisis quis massa.
                    </p>
                    <p className="profile-text">
                      3. Lacus mattis placerat sapien nulla pulvinar a et massa.
                      Est consectetur donec id quisque est facilisis quis massa.
                    </p>
                  </div>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                style={{
                  background: "#fff",
                  borderRadius: "16px",
                  padding: "20px",
                  marginTop: "20px",
                }}
              >
                <Grid xs={12} item>
                  <p className="profile-headings">Paramètres</p>
                </Grid>

                <Grid xs={12} item>
                  <div style={{ display: "flex", width: "100%" }}>
                    <div style={{ flex: 1 }}>
                      <p className="profile-text">Compte</p>
                    </div>
                    <div>
                      <p
                        className="profile-text"
                        style={{ fontWeight: "600", color: "#ee6931" }}
                      >
                        Changer le mot de passe
                      </p>
                    </div>
                  </div>

                  <div style={{ display: "flex", width: "100%" }}>
                    <div style={{ flex: 1 }}>
                      <p className="profile-text">Notifications</p>
                    </div>
                    <div>
                      <CustomSwitch />
                    </div>
                  </div>

                  <div style={{ display: "flex", width: "100%" }}>
                    <div style={{ flex: 1 }}>
                      <p className="profile-text">Paiement</p>
                    </div>
                    <div>
                      <p
                        className="profile-text"
                        style={{ fontWeight: "600", color: "#ee6931" }}
                      >
                        Voir
                      </p>
                    </div>
                  </div>

                  <div style={{ display: "flex", width: "100%" }}>
                    <div style={{ flex: 1 }}>
                      <p className="profile-text">Langue</p>
                    </div>
                    <div>
                      <p
                        className="profile-text"
                        style={{ fontWeight: "600", color: "#ee6931" }}
                      >
                        Changer
                      </p>
                    </div>
                  </div>

                  <div style={{ display: "flex", width: "100%" }}>
                    <div style={{ flex: 1 }}>
                      <p className="profile-text">Conditions générales</p>
                    </div>
                    <div>
                      <p
                        className="profile-text"
                        style={{ fontWeight: "600", color: "#ee6931" }}
                      >
                        Voir
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Grid container spacing={1} style={{ columnGap: "50px" }}>
                <Grid
                  xs={7}
                  item
                  style={{
                    background: "#fff",
                    borderRadius: "16px",
                    padding: "20px",
                    // marginRight:'10px'
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid xs={12} item>
                      <p
                        className="profile-headings"
                        style={{ fontSize: "24px" }}
                      >
                        Transactions
                      </p>
                    </Grid>

                    {templeteData?.map((i: any, index: any) => {
                      return (
                        <>
                          <Grid
                            xs={12}
                            item
                            style={{
                              borderBottom: "1px solid #E0E0E0",
                              margin: "0px 20px 0px 20px",
                            }}
                          >
                            <div style={{ display: "flex" }}>
                              <div style={{ flex: 1 }}>
                                <p
                                  className="profile-headings"
                                  style={{ fontSize: "18px" }}
                                >
                                  {" "}
                                  € &nbsp; {i?.paymentValue ?? "0.00"}
                                </p>
                                <p className="profile-text">
                                  {" "}
                                  {i?.paymentDate ?? "10/02/22"}
                                </p>
                              </div>
                              <div className="display-flex">
                                <span
                                  style={{
                                    padding: "10px 15px 10px 15px",
                                    borderRadius: "12px",
                                    background:
                                      i?.paymentStatus === "Payé"
                                        ? "#BBF4D0"
                                        : "#FBACAC",
                                    color:
                                      i?.paymentStatus === "Payé"
                                        ? "darkgreen"
                                        : "#B91C1C",
                                    textAlign: "center",
                                    fontFamily: "Raleway-Variable",
                                    fontWeight: "600",
                                  }}
                                >
                                  {i?.paymentStatus}
                                </span>
                              </div>
                            </div>
                          </Grid>
                        </>
                      );
                    })}
                    <Grid xs={12} item className="display-flex">
                      <p
                        className="profile-headings display-flex"
                        style={{
                          color: "#3026f1",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        <RefrestIcon /> Charger plus de transactions
                      </p>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  xs={4}
                  item
                  style={{
                    padding: "20px",
                    background: "#fff",
                    borderRadius: "16px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid xs={12} item>
                      <p
                        className="profile-headings"
                        style={{ fontSize: "24px" }}
                      >
                        Mon porte monnaie
                      </p>
                    </Grid>

                    <Grid xs={12} item>
                      <div
                        style={{
                          background: "#F4F3F6",
                          borderRadius: "12px",
                          padding: "30px 100px 30px 100px",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "52px",
                            fontWeight: "800",
                            fontFamily: "Raleway-Variable",
                            textAlign: "center",
                          }}
                        >
                          <span style={{ color: "grey", fontWeight: "600" }}>
                            €
                          </span>
                          240.<span style={{ fontWeight: "600" }}> 50</span>
                        </p>
                      </div>
                    </Grid>
                    <Grid xs={12} item>
                      <div
                        style={{
                          paddingTop:'40px',
                          position: "relative",
                          zIndex: 111,
                          flexDirection: "column",
                        }}
                        className="display-flex"
                      >
                        <img
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "220px",
                          }}
                          src={orangeBackground}
                          alt=""
                        />
                        <p
                          style={{
                            fontSize: "22px",
                            fontWeight: "800",
                            fontFamily: "Raleway-Variable",
                            color: "#fff",
                            zIndex: 111,
                          }}
                        >
                          Merci Frank
                        </p>

                        <CustomButton
                          style={{
                            background: "#fff",
                            color: "#ee6931",
                            fontSize: "16px",
                            fontFamily: "Raleway-Variable",
                            border: "none",
                            borderRadius: "12px",
                            // padding: "10px 10px 10px 10px",
                            width: "200px",
                          }}
                          value={"Transférer vers un compte bancaire"}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              Item Three
            </CustomTabPanel>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default Profile;
